<template>
  <v-container fluid class=" fill-height pa-0 ma-0">
      <v-row>
        <v-flex justify-center class="d-flex justify-center fill-width">
          <v-col class=""
            cols="4"
          >
            <v-card elevation="5" height="400" class="rounded-lg">
              <div class="d-flex flex-column justify-center align-center fill-height">
                <div class="text-h4">Welcome</div>
                <v-form
                >
                  <v-container fluid>
                    <div>    
                      <v-text-field
                        v-model="loginInfo.username"
                        counter
                        :rules="[rules.required, rules.min]"
                        label="Email/phone"
                        class="fill-width"
                        required
                      ></v-text-field>
                    </div>
                    <div>
                      <v-text-field
                        v-model="loginInfo.password"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required, rules.min]"
                        :type="show1 ? 'text' : 'password'"
                        name="input-10-1"
                        label="password"
                        hint="At least 8 characters"
                        counter
                        @click:append="show1 = !show1"
                      ></v-text-field>
                    </div>
                  </v-container>
                  <div class='d-flex justify-center'>Login as:</div>
                  <div class="d-flex justify-center">
                    <v-btn
                      large
                      color="red"
                      elevation="2"
                      class="white--text"
                      @click="login('owner')"
                    >Owner</v-btn>
                    <v-btn
                      large
                      color="red"
                      elevation="2"
                      class="white--text"
                      @click="login('admin')"
                    >Admin</v-btn>
                    <v-btn
                      large
                      color="red"
                      elevation="2"
                      class="white--text"
                      @click="login('tutor')"
                    >Tutor</v-btn>
                  </div>
                </v-form>

              </div>
            </v-card>
          </v-col>
        </v-flex>
      </v-row>
  </v-container>
</template>

<script>
/* eslint-disable */
import axios from 'axios'
import { mapActions } from "vuex";

export default {
  name:'LoginView',
  data () {
    return {
      loginInfo:{
        username: '',
        password: ''
      },
      show1: false,
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters',
        emailMatch: () => (`The email and password you entered don't match`),
      },
    }
  },
  computed:{
  },
  methods:{
    ...mapActions(["LogIn"]),
    async login(type){
      const User = new FormData();
      User.append("username", this.loginInfo.username);
      User.append("password", this.loginInfo.password);
      try {
      await this.LogIn({User,type}).then(
        res=>{
          this.$toasted.show('Login Success', {type:'success'})
          this.$router.push({ name: `Dashboard` })
        },error =>{
          if(error == 'E000')
          {this.$toasted.show('Please Fill the Required Field', {type:'error'})}
          else if(error == 'E001'){
            this.$toasted.show('You dont have access', {type:'error'})
          } else {
            this.$toasted.show('Wrong Credential', {type:'error'})
          }
          console.log(error)
        }
      );
      } catch (error) {
        alert(error)
      }
    }
  }
}
</script>

<style>

</style>'
<template>
  <basic-layout>
    <!--CREATE DIALOG -->
    <v-dialog
        v-model="createDialog"
        width="500"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Manage Access : {{selectedData.username}}
          </v-card-title>

          <v-card-text class="pa-5">
            <div>
              Current Access:
              <div class="mb-2">
              <sub >Click to remove</sub>
              </div>
                <v-btn
                  v-for="(list, index) in selectedData.access"
                  :key="index"
                  :color="list.type == `admin` ?  `red` : `red`"
                  class="mx-2 mb-2"
                  @click="removeAccess(list.type)"
                  >{{list.type}}
                </v-btn>
            </div>
            <v-divider></v-divider>
            <div class="mt-2">
              Add Access
              <v-btn
                v-for="(list, index) in [{type:'admin'},{type:'tutor'}]"
                :key="index"
                :color="list.type == `admin` ?  `green` : `green`"
                class="mx-2 mb-2"
                @click="addAccess(list.type)"
                >{{list.type}}
              </v-btn>
            </div>

          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red"
              text
              @click="createDialog = false ;selectedData={}"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
<!-- ENDDIALOG -->
    <v-container fluid class=""
    >
      <v-data-table
        :headers="headers"
        :items="data"
        item-key="name"
        class="elevation-1"
        :search="search"
        :loading=dataLoading
      >
        <template v-slot:top>
          <v-text-field
            v-model="search"
            label="Search"
            class="mx-4"
          ></v-text-field>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>{{item.profile.full_name}}</td>
            <td>{{item.username}}</td>
            <td>
              <v-btn
              disabled
              v-for="(list, index) in item.access"
              :key="index"
              :color="list.type == `Admin` ?  `yellow` : `blue`"
              class="mx-2"
              >{{list.type}}
              </v-btn>
            </td>
            <td>
              <v-btn icon color="black" outlined
              @click="selectUser(item);createDialog=true"
              >
                <v-icon>mdi mdi-plus</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>

      </v-data-table>
    </v-container>
  </basic-layout>
</template>

<script>
// @ is an alias to /src
import BasicLayout from '@/components/BasicLayout.vue'
export default {
  name: 'HomeView',
  components: {
    BasicLayout
  },
  data () {
    return {
      dataLoading: true,
      search: '',
      selectedData: {},
      createDialog: false,
      data: [
      ],
    }
  },computed:{
    headers () {
        return [
          {
            text: 'Name',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          {
            text: 'Email',
            align: 'start',
            sortable: true,
            value: 'username',
          },
          {
            text: 'Access',
            align: 'start',
            sortable: false,
            value: 'access',
          },
          {
            text: 'Action',
            align: 'start',
            sortable: false,
          },
        ]
    }
  },
  methods:{
    getData(){
      window.axios.get('/user/get').then(
        res=>{
          console.log(res)
          this.data = res.data.data
          this.dataLoading=false
        }
      )
    },
    selectUser(user){
      this.selectedData = user
      console.log(this.selectedData)
    },
    addAccess(type){
      window.axios.post('/access/grant',{
        user_id: this.selectedData.id,
        type: type,
      }).then(
        res=>{
          if(res.data.error){
            this.$toasted.show(res.data.message, {type:'error'})
          }else{
            this.$toasted.show(res.data.message, {type:'success'})
          }
          this.getData()
          this.createDialog = false
        }
      )
    },
    removeAccess(type){
      window.axios.post('/access/remove',{
        user_id: this.selectedData.id,
        type: type,
      }).then(
        res=>{
          if(res.data.error){
            this.$toasted.show(res.data.message, {type:'error'})
          }else{
            this.$toasted.show(res.data.message, {type:'success'})
          }
          this.getData()
          this.createDialog = false
        }
      )
    },
  },
  mounted(){
    this.getData()
  }
}
</script>

<template>
  <basic-layout>
    <v-container fluid class=""
    >
      <v-data-table
        :headers="headers"
        :items="data"
        item-key="name"
        class="elevation-1"
        :search="search"
        :loading=dataLoading
      >
        <template v-slot:top>
          <v-text-field
            v-model="search"
            label="Search"
            class="mx-4"
          ></v-text-field>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>{{item.profile.full_name}}</td>
            <td>{{item.profile.email}}</td>
            <td>
              <v-btn icon color="black" outlined
              @click="$router.push({path:'/student/'+item.id})"
              >
                <v-icon>mdi mdi-eye</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>

      </v-data-table>
    </v-container>
  </basic-layout>
</template>

<script>
// @ is an alias to /src
import BasicLayout from '@/components/BasicLayout.vue'
export default {
  name: 'HomeView',
  components: {
    BasicLayout
  },
  data () {
    return {
      dataLoading: true,
      search: '',
      selectedData: {},
      createDialog: false,
      data: [
      ],
    }
  },computed:{
    headers () {
        return [
          {
            text: 'Name',
            align: 'start',
            sortable: true,
            value: 'profile.full_name',
          },
          {
            text: 'Email',
            align: 'start',
            sortable: true,
            value: 'profile.email',
          },
          {
            text: 'Action',
            align: 'start',
            sortable: false,
          },
        ]
    }
  },
  methods:{
    getData(){
      window.axios.get('/user/get').then(
        res=>{
          console.log(res)
          this.data = res.data.data
          this.dataLoading=false
        }
      )
    },
    selectUser(user){
      this.selectedData = user
      console.log(this.selectedData)
    },
  },
  mounted(){
    this.getData()
  }
}
</script>

<template>
  <basic-layout>
    <!-- Material DIALOG -->
    <v-dialog
        v-model="materialDialog"
        width="800"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Material
          </v-card-title>

          <v-card-text class="pa-5">
            <v-data-table
              :headers="headersMaterial"
              :items="dataMaterial"
              item-key="name"
              class="elevation-1"
              :search="search"
              :loading=dataLoading
            >
              <template v-slot:top>
                <v-container>
                  <v-row>
                    <v-col>
                      <v-btn color="red" @click="createMaterialDialog = true">CREATE</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
                <v-text-field
                  v-model="search"
                  label="Search"
                  class="mx-4"
                ></v-text-field>
              </template>
              <template v-slot:item="{ item }">
                <tr>
                  <td>{{item.name}}</td>
                  <td>
                    <v-btn icon color="black" outlined
                    @click="deleteMaterial(item)"
                    >
                      <v-icon>mdi mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>

            </v-data-table>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red"
              text
              @click="materialDialog = false ;"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <!-- ENDDIALOG -->
    <!-- Create Material DIALOG -->
    <v-dialog
        v-model="createMaterialDialog"
        width="800"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Create Material
          </v-card-title>

          <v-card-text class="pa-5">
            <div>
              <v-text-field
                v-model="selectedMaterial.name"
                counter
                :rules="[rules.required]"
                label="Name"
                class="fill-width"
                required
              ></v-text-field>
            </div>
            <div>
              <v-file-input
                label="PDF"
                truncate-length="15"
                id="pdf"
              ></v-file-input>
            </div>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red"
              text
              @click="addMaterial();"
            >
              Add
            </v-btn>
            <v-btn
              color="red"
              text
              @click="createMaterialDialog = false ;"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <!-- ENDDIALOG -->
    <!-- Create Attend DIALOG -->
    <v-dialog
        v-model="attendDetailDialog"
        width="800"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Student Recap
          </v-card-title>

          <v-card-text class="pa-5">
            <div>
              <v-text-field
                v-model="selectedAttend.profile.full_name"
                counter
                :rules="[rules.required]"
                label="Name"
                class="fill-width"
                readonly
                required
              ></v-text-field>
            </div>
            <div>
              <v-select
                :items="[{value:1,text:'Hadir'},{value:-1,text:'Tidak Hadir'},{value:0,text:'Menontor Video'}]"
                label="attendance"
                dense
                v-model="selectedAttend.attend.attendance"
              ></v-select>
            </div>
            <div>
              <v-text-field
                v-model="selectedAttend.attend.score"
                counter
                :rules="[rules.required]"
                label="Score"
                class="fill-width"
                required
              ></v-text-field>
            </div>
            <div>
              <v-text-field
                v-model="selectedAttend.attend.remarks"
                counter
                :rules="[rules.required]"
                label="Remarks"
                class="fill-width"
                required
              ></v-text-field>
            </div>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red"
              text
              @click="saveAttend();"
            >
              Save
            </v-btn>
            <v-btn
              color="red"
              text
              @click="attendDetailDialog = false ;"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <!-- ENDDIALOG -->
    <!-- Attend DIALOG -->
    <v-dialog
        v-model="attendDialog"
        width="1000"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Attendance
          </v-card-title>

          <v-card-text class="pa-5">
            <v-data-table
              :headers="headersAttend"
              :items="dataAttend"
              item-key="name"
              class="elevation-1"
              :search="search"
              :loading=dataLoading
            >
              <template v-slot:top>
              </template>
              <template v-slot:item="{ item }">
                <tr>
                  <td>
                    {{item.profile.full_name}}
                  </td>
                  <td>
                    {{item.attend.attendance == null ? 'Not Recapped': item.attend.attendance}}
                  </td>
                  <td>
                    {{item.attend.score == null ? 'Not Recapped': item.attend.score}}
                  </td>
                  <td>
                    <v-btn icon color="black" outlined
                    class="mx-1"
                    @click="openAttendDetailDialog(item);"
                    >
                      <v-icon>mdi mdi-eye</v-icon>
                    </v-btn>
                  </td>
                  <td>
                    <v-btn icon :color="item.attend.suggest_type=='up'? 'red' : 'black' " outlined
                    class="mx-1"
                    @click="setSuggest(item,'up');"
                    >
                      <v-icon>mdi mdi-arrow-up</v-icon>
                    </v-btn>
                    <v-btn icon :color="item.attend.suggest_type=='down'? 'red' : 'black' " outlined
                    class="mx-1"
                    @click="setSuggest(item,'down');"
                    >
                      <v-icon>mdi mdi-arrow-down</v-icon>
                    </v-btn>
                    <v-btn icon :color="item.attend.suggest_type=='stay'? 'red' : 'black' " outlined
                    class="mx-1"
                    @click="setSuggest(item,'stay');"
                    >
                      <v-icon>mdi mdi-minus</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>

            </v-data-table>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red"
              text
              @click="attendDialog = false ;"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <!-- ENDDIALOG -->
    <!-- CREATE DIALOG -->
    <v-dialog
        v-model="createDialog"
        width="800"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Meet
          </v-card-title>

          <v-card-text class="pa-5">
            <div>
              <v-text-field
                v-model="selectedData.topic"
                counter
                :rules="[rules.required]"
                label="Topic"
                class="fill-width"
                required
              ></v-text-field>
            </div>
            <div>
              Date:
                <v-date-picker  v-model="selectedData.date" show-current=""></v-date-picker>
            </div>
            <div>
              Description:
              <quill-editor
                  ref="editor"
                  v-model="selectedData.description"
                  @change="onEditorChange($event)"
                  style="height:100%;"
              />
            </div>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red"
              text
              @click="create()"
            >
              Save
            </v-btn>
            <v-btn
              color="red"
              text
              @click="createDialog = false ;selectedData={}"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <!-- ENDDIALOG -->
    <!-- CREATE DIALOG -->
    <v-dialog
        v-model="updateDialog"
        width="800"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Meet
          </v-card-title>

          <v-card-text class="pa-5">
            <div>
              <v-text-field
                v-model="selectedData.topic"
                counter
                :rules="[rules.required]"
                label="Topic"
                class="fill-width"
                required
              ></v-text-field>
            </div>
            <div>
              Date:
                <v-date-picker  v-model="selectedData.date" show-current=""></v-date-picker>
            </div>
            <div>
              Description:
              <quill-editor
                  ref="editor"
                  v-model="selectedData.description"
                  @change="onEditorChange($event)"
                  style="height:100%;"
              />
            </div>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red"
              text
              @click="update()"
            >
              Save
            </v-btn>
            <v-btn
              color="red"
              text
              @click="updateDialog = false ;selectedData={}"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <!-- ENDDIALOG -->
    <v-container fluid class=""
    >
      <v-btn color="red" @click="$router.push({path:'/batch'})">Back</v-btn>
      <v-data-table
        :headers="headers"
        :items="data"
        item-key="name"
        class="elevation-1"
        :search="search"
        :loading="dataLoading"
      >
        <template v-slot:top>
          <v-container>
            <v-row>
              <v-col>
                <b>{{dataBatch.name}}</b>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn color="red" @click="createDialog = true">CREATE</v-btn>
              </v-col>
            </v-row>
          </v-container>
          <v-text-field
            v-model="search"
            label="Search"
            class="mx-4"
          ></v-text-field>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>{{item.topic}}</td>
            <td>{{item.date}}</td>
            <td>
              <v-btn icon color="black" outlined
              @click="openUpdateDialog(item)"
              >
                <v-icon>mdi mdi-eye</v-icon>
              </v-btn>
              <v-btn icon color="black" outlined
              @click="deletes(item)"
              >
                <v-icon>mdi mdi-delete</v-icon>
              </v-btn>
            </td>
            <td>
              <v-btn icon color="black" outlined
              @click="openMaterialDialog(item)"
              >
                <v-icon>mdi mdi-eye</v-icon>
              </v-btn>
            </td>
            <td>
              <v-btn icon color="black" outlined
              @click="openAttendDialog(item)"
              >
                <v-icon>mdi mdi-eye</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>

      </v-data-table>
    </v-container>
  </basic-layout>
</template>

<script>
// @ is an alias to /src
import BasicLayout from '@/components/BasicLayout.vue'
export default {
  name: 'HomeView',
  components: {
    BasicLayout
  },
  data () {
    return {
      article:'',
      dataLoading: true,
      search: '',
      data: [
      ],
      dataBatch: [
      ],
      dataMaterial: [
      ],
      dataAttend: [
      ],
      createDialog : false,
      updateDialog : false,
      materialDialog : false,
      createMaterialDialog : false,
      attendDialog : false,
      attendDetailDialog : false,
      selectedData: {},
      selectedMaterial:{},
      selectedAttend:{
        profile:{
          full_name:''
        },
        attend:{

        }
      },
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters',
        emailMatch: () => (`The email and password you entered don't match`),
      },
    }
  },computed:{
    headers () {
        return [
          {
            text: 'Topic',
            align: 'start',
            sortable: true,
            value: 'topic',
          },
          {
            text: 'Date',
            align: 'start',
            sortable: true,
            value: 'date',
          },
          {
            text: 'Action',
            align: 'start',
            sortable: false,
          },
          {
            text: 'Material',
            align: 'start',
            sortable: false,
          },
          {
            text: 'Attendance',
            align: 'start',
            sortable: false,
          },
        ]
    },
    headersMaterial () {
        return [
          {
            text: 'Name',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          {
            text: 'Action',
            align: 'start',
            sortable: false,
          },
        ]
    },
    headersAttend () {
        return [
          {
            text: 'Name',
            align: 'start',
            sortable: true,
            value: 'topic',
          },
          {
            text: 'A8ttendance',
            align: 'start',
            sortable: true,
            value: 'attendance',
          },
          {
            text: 'Score',
            align: 'start',
            sortable: true,
            value: 'score',
          },
          {
            text: 'Action',
            align: 'start',
            sortable: false,
          },
          {
            text: 'Suggest Move',
            align: 'start',
            sortable: false,
          },
        ]
    }
  },
  methods:{
    onEditorChange({ quill, html, text,source}) {
      console.log('editor change!', quill, html, text,source)
      this.selectedData.description = html
    },
    getData(){
      window.axios.get('/meet/'+this.$route.params.id).then(
        res=>{
          console.log(res)
          this.data = res.data.data
          this.dataLoading=false
        }
      )
      window.axios.get('/batch/detail/'+this.$route.params.id).then(
        res=>{
          console.log(res)
          this.dataBatch = res.data.data
          this.dataLoading=false
        }
      )
    },
    create(){
      this.$toasted.show('Please Wait...', {type:'success'})
      window.axios.post('/meet/create',{
        batch_id: this.dataBatch.id,
        topic: this.selectedData.topic,
        description: this.selectedData.description,
        date: this.selectedData.date,

      }).then(
        res=>{
          if(res.data.error){
            this.$toasted.show(res.data.message, {type:'error'})
          }else{
            this.$toasted.show(res.data.message, {type:'success'})
          }
          this.createDialog =false
          this.getData()
        }
      )
    },
    update(){
      this.$toasted.show('Please Wait...', {type:'success'})
      window.axios.post('/meet/update/'+this.selectedData.id,{
        topic: this.selectedData.topic,
        description: this.selectedData.description,
        date: this.selectedData.date,
      }).then(
        res=>{
          if(res.data.error){
            this.$toasted.show(res.data.message, {type:'error'})
          }else{
            this.$toasted.show(res.data.message, {type:'success'})
          }
          this.createDialog =false
          this.getData()
        }
      )
    },
    deletes(item){
      this.$toasted.show('Please Wait...', {type:'success'})
      window.axios.post('/meet/delete/'+item.id,{
        topic: this.selectedData.topic,
        description: this.selectedData.description,
        date: this.selectedData.date,
      }).then(
        res=>{
          if(res.data.error){
            this.$toasted.show(res.data.message, {type:'error'})
          }else{
            this.$toasted.show(res.data.message, {type:'success'})
          }
          this.createDialog =false
          this.getData()
        }
      )
    },
    getDataMaterial(id){
      window.axios.get('/meet/detail/'+id).then(
        res=>{
          this.dataMaterial = res.data.data.material
          this.dataLoading=false
          this.materialDialog = true
        }
      )
    },
    getDataAttend(id){
      this.dataLoading=true
      window.axios.get('/user/attend/meet/'+id).then(
        res=>{
          this.dataAttend = res.data.data.userList
          this.dataLoading=false
          this.attendDialog = true
        }
      )
    },
    addMaterial(){
      var formData = new FormData()
      formData.append('meet_id',this.selectedData.id)

      var pdfFiles = document.querySelector('#pdf');
      formData.append("material", pdfFiles.files[0]);

      formData.append('name',this.selectedMaterial.name)

      this.$toasted.show('Please Wait...', {type:'success'})
      window.axios.post('/meet/material/add',
      formData
      ).then(
        res=>{
          if(res.data.error){
            this.$toasted.show(res.data.message, {type:'error'})
          }else{
            this.$toasted.show(res.data.message, {type:'success'})
          }
          this.getDataMaterial(this.selectedData.id)
          this.createMaterialDialog =false
        }
      )
    },
    deleteMaterial(item){
      this.$toasted.show('Please Wait...', {type:'success'})
      window.axios.post('/meet/material/delete/'+item.id,
      ).then(
        res=>{
          if(res.data.error){
            this.$toasted.show(res.data.message, {type:'error'})
          }else{
            this.$toasted.show(res.data.message, {type:'success'})
          }
          this.getDataMaterial(this.selectedData.id)
        }
      )
    },
    saveAttend(){
      this.$toasted.show('Please Wait...', {type:'success'})
      window.axios.post('/user/attend/set',{
        user_id: this.selectedAttend.user_id,
        meet_id: this.selectedData.id,
        score: this.selectedAttend.attend.score,
        attendance: this.selectedAttend.attend.attendance,
        remarks: this.selectedAttend.attend.remarks,
      }).then(
        res=>{
          if(res.data.error){
            this.$toasted.show(res.data.message, {type:'error'})
          }else{
            this.$toasted.show(res.data.message, {type:'success'})
          }
          this.attendDetailDialog =false
          this.getDataAttend(this.selectedData.id)
        }
      )
    },
    setSuggest(item,type){
      this.$toasted.show('Please Wait...', {type:'success'})
      window.axios.post('/user/move/suggest/set',{
        user_id: item.user_id,
        meet_id: this.selectedData.id,
        suggest_type: type,
      }).then(
        res=>{
          if(res.data.error){
            this.$toasted.show(res.data.message, {type:'error'})
          }else{
            this.$toasted.show(res.data.message, {type:'success'})
          }
          this.getDataAttend(this.selectedData.id)
        }
      )
    },
    openUpdateDialog(item){
      this.updateDialog = true
      this.selectedData =item
    },
    openMaterialDialog(item){
      this.selectedData =item
      this.getDataMaterial(item.id)
    },
    openAttendDialog(item){
      this.selectedData =item
      this.getDataAttend(item.id)
    },
    openAttendDetailDialog(item){
      console.log(item)
      this.selectedAttend=item
      this.attendDetailDialog = true
    }
  },
  mounted(){
    this.getData()
    this.dataLoading=false
  }
}
</script>

<template>
  <basic-layout>
    <v-container fluid class=""
    >
      <quill-editor
          ref="editor"
          v-model="article"
          @change="onEditorChange($event)"
          style="height:100%;"
      />
      <v-btn class="mt-5" color="success" @click="save">Save</v-btn>
    </v-container>
  </basic-layout>
</template>

<script>
// @ is an alias to /src
import BasicLayout from '@/components/BasicLayout.vue'
export default {
  name: 'HomeView',
  components: {
    BasicLayout
  },
  data () {
    return {
      article:'',
      dataLoading: true,
      search: '',
      data: [
      ],
      createDialog : false,
      updateDialog : false,
      selectedData: {},
    }
  },computed:{
    headers () {
        return [
          {
            text: 'Question',
            align: 'start',
            sortable: true,
            value: 'question',
          },
          {
            text: 'Answer',
            align: 'start',
            sortable: true,
            value: 'answer',
          },
          {
            text: 'Action',
            align: 'start',
            sortable: false,
          },
        ]
    }
  },
  methods:{
    onEditorChange({ quill, html, text,source}) {
      console.log('editor change!', quill, html, text,source)
      this.article = html
    },
    getData(){
      window.axios.get('/guest/home/content/get/privacy').then(
        res=>{
          console.log(res)
          this.article = res.data.data
          this.dataLoading=false
        }
      )
    },
    save(){
      this.$toasted.show('Please Wait...', {type:'success'})
      window.axios.post('/home/content/update/privacy',{
        content: this.article,
      }).then(
        res=>{
          if(res.data.error){
            this.$toasted.show(res.data.message, {type:'error'})
          }else{
            this.$toasted.show(res.data.message, {type:'success'})
          }
          this.createDialog =false
          this.getData()
        }
      )
    },
  },
  mounted(){
    this.getData()
    this.dataLoading=false
  }
}
</script>

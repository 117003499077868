<template>
  <basic-layout>
    <v-container fluid class=""
    >
      <v-data-table
        :headers="headers"
        :items="data"
        class="elevation-1"
        :loading=dataLoading
        :search="search"
      >
        <template v-slot:top>
          <v-text-field
            v-model="search"
            label="Search"
            class="mx-4"
          ></v-text-field>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>{{item.invoice_code}}</td>
            <td>{{item.payment != undefined ? item.payment.name : ''}}</td>
            <td>{{item.packet != undefined ? item.packet.name : ''}}</td>
            <td>{{item.status}}</td>

            <td>
              <v-btn icon color="black" outlined>
                <v-icon @click="openDetail(item)">mdi mdi-eye</v-icon>
              </v-btn>
              <v-btn class="ml-2" icon color="black" outlined
              v-if="item.status==0 && item.payment.name == 'Manual Transfer'"
              >
                <v-icon @click="openActivate(item)">mdi mdi-check-bold</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>

      </v-data-table>

      <!-- DIALOG -->
      <v-dialog
        v-model="dialogDetail"
        width="60%"
        persistent
      >
        <v-card>
          <v-card-title class="text-h6 red">
            Detail Payment
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="selectedItem.invoice_code"
                    counter
                    label="INVOICE CODE"
                    class="fill-width"
                    readonly
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="selectedItem.payment.name"
                    counter
                    label="Type"
                    class="fill-width"
                    readonly
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="selectedItem.users.full_name"
                    counter
                    label="Name"
                    class="fill-width"
                    readonly
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="selectedItem.price"
                    counter
                    label="Price"
                    class="fill-width"
                    readonly
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="selectedItem.packet.name"
                    counter
                    label="Package"
                    class="fill-width"
                    readonly
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red"
              text
              @click="dialogDetail = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- DIALOG -->
      <v-dialog
        v-model="dialogActive"
        width="60%"
        persistent
      >
        <v-card>
          <v-card-title class="text-h6 red">
            Are You Sure?
          </v-card-title>

          <v-card-text>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red"
              text
              @click="activate"
            >
              Activate
            </v-btn>
            <v-btn
              color="red"
              text
              @click="dialogActive = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </basic-layout>
</template>

<script>
// @ is an alias to /src
import BasicLayout from '@/components/BasicLayout.vue'
export default {
  name: 'HomeView',
  components: {
    BasicLayout
  },
  data () {
    return {
      dialogDetail:false,
      dataLoading: false,
      selectedItem: {
        payment:{},
        users:{},
        packet:{}
      },
      dialogActive: false,
      search: '',
        data: [
        ],
    }
  },computed:{
    headers () {
        return [
          {
            text: 'Invoice',
            align: 'start',
            sortable: true,
            filterable: true,
            value: 'invoice_code',
          },
          {
            text: 'Type',
            align: 'start',
            sortable: true,
            value: 'username',
          },
          {
            text: 'Package',
            align: 'start',
            sortable: true,
            value: 'packageName',
          },
          {
            text: 'Status',
            align: 'start',
            sortable: true,
            filterable: false,
            value: 'status',
          },
          {
            text: 'Action',
            align: 'start',
            sortable: false,
          },
        ]
    }
  },
  methods:{
    openDetail (item) {
      console.log(item)
      this.dialogDetail=true;
      this.selectedItem = item
    },
    openActivate (item) {
      console.log(item)
      this.selectedItem = item
      this.dialogActive=true;
    },
    getData(){
      this.dataLoading=true
      window.axios.get('/order').then(
        res=>{
          console.log(res)
          this.data = res.data.data
          this.dataLoading=false
        }
      )
    },
    activate(){
      this.$toasted.show('please wait...', {type:'success'})
      window.axios.post('/guest/order/status/'+this.selectedItem.invoice_code,{
        status: 1,
        is_process:1
      }).then(
        res=>{
          console.log(res)
          if(res.data.error){
            this.$toasted.show(res.data.message, {type:'error'})
          }else{
            this.$toasted.show(res.data.message, {type:'success'})
          }
          this.getData()
        }
      )
    }
  },
  mounted(){
    this.getData()
  }
}
</script>

import Vue from 'vue'
import './plugins/axios'
import './plugins/quill'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Toasted from './plugins/Toasted'
import Axios from 'axios'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import VuePolling from 'vue-polling';
Vue.use(Loading);
Vue.use(VuePolling)
Vue.prototype.$axios = Axios;

Axios.defaults.baseURL = 'https://kwikku.in/eie-rebase/public/api'
Axios.defaults.withCredentials = true

Vue.config.productionTip = false
new Vue({
  vuetify,
  router,
  store,
  Toasted,
  render: h => h(App)
}).$mount('#app')

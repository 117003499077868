<template>
  <basic-layout>
    <!-- schedule off dialog -->
    <v-dialog
      v-model="scheduleOffDialog"
      width="800"
      persistent
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Add Off Day
        </v-card-title>

        <v-card-text class="pa-5">
          <v-container>
            <v-row>
              <v-col>
                <v-date-picker  v-model="scheduleOffData.date" range show-current=""></v-date-picker>
              </v-col>
              <v-col
              cols="7"
              >
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="scheduleOffData.title"
                      counter
                      label="Title"
                      class="fill-width"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-file-input
                      label="Image"
                      truncate-length="15"
                      id="image"
                    ></v-file-input>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            text
            @click="create()"
          >
            Save
          </v-btn>
          <v-btn
            color="red"
            text
            @click="scheduleOffDialog = false;"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog -->
    <!-- schedule off dialog -->
    <v-dialog
      v-model="scheduleOffDetailDialog"
      width="800"
      persistent
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Detail Off Day
        </v-card-title>

        <v-card-text class="pa-5">
          <v-container>
            <v-row>
              <v-col>
                <v-date-picker  v-model="scheduleOffData.date"  range show-current=""></v-date-picker>
              </v-col>
              <v-col
              cols="7"
              >
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="scheduleOffData.title"
                      counter
                      label="Title"
                      class="fill-width"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
              <img width="200" :src="apiUrl+'/api/guest/asset/image/off/'+scheduleOffData.image_url" alt="">
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-file-input
                      label="Image"
                      truncate-length="15"
                      id="image"
                    ></v-file-input>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            text
            @click="deletes()"
          >
            Delete
          </v-btn>
          <v-btn
            color="red"
            text
            @click="update()"
          >
            Save
          </v-btn>
          <v-btn
            color="red"
            text
            @click="scheduleOffDetailDialog = false;scheduleOffData={}"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog -->
    <v-container fluid class=""
    >
      <v-row>
        <v-col>
          <v-date-picker  v-model="date" show-current="" @change="getData"></v-date-picker>
        </v-col>
        <v-col>
          <v-btn color="red" @click="scheduleOffDialog = true">
            Add Off Day
          </v-btn>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
      <v-calendar
        v-model="date"
        ref="calendar"
        type="category"
        category-show-all
        :categories="categories"
        :events="events"
        :event-color="getEventColor"
        @change="fetchEvents"
        @click:event="openEventDialog"
      ></v-calendar>
    </v-container>
  </basic-layout>
</template>

<script>
// @ is an alias to /src
import BasicLayout from '@/components/BasicLayout.vue'
export default {
  name: 'HomeView',
  components: {
    BasicLayout
  },
  data () {
    return {
      dataLoading: true,
      search: '',
      data: [
      ],
      scheduleOffDialog : false,
      scheduleOffDetailDialog : false,
      selectedData: {},
      scheduleOffData:{
        date:[]
      },
      date:'',
      selectedEvent:{},
      categories:['Off Day','Class'],
      colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
      events:[
      ],
      resultOff:[],
      apiUrl : ''
    }
  },computed:{
    headers () {
        return [
          {
            text: 'Question',
            align: 'start',
            sortable: true,
            value: 'question',
          },
          {
            text: 'Answer',
            align: 'start',
            sortable: true,
            value: 'answer',
          },
          {
            text: 'Action',
            align: 'start',
            sortable: false,
          },
        ]
    },
    now() {
      const date = new Date();
      var day = date.getDate();
      const year = date.getFullYear();
      var month = date.getMonth()+1;
      month = month.toString()
      day = day.toString()
      if(month.length == 1){
        month = '0'+month
      }
      if(day.length == 1){
        day = '0'+day
      }

      return year+'-'+month+'-'+day
    }
  },
  methods:{
    getData(){
      let loader = this.$loading.show({
        // Optional parameters
        container: null,
      });
      if( this.date == ''){
        this.date = this.now
      }
      window.axios.get('/schedule/off/day?date='+this.date).then(
        res=>{
          this.resultOff = res.data.data
          this.fetchEvents()
          console.log(this.resultOff)
          loader.hide()
        }
      )
    },
    getEventColor (event) {
        return event.color
    },
    create(){
      var formData = new FormData()
      formData.append('date_start',this.scheduleOffData.date[0])
      formData.append('date_end',this.scheduleOffData.date[1])
      formData.append('title',this.scheduleOffData.title)
      var imagefile = document.querySelector('#image');
      formData.append("image", imagefile.files[0]);

      this.$toasted.show('Please Wait...', {type:'success'})
      window.axios.post('/schedule/off/create',
        formData
      ,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      ).then(
        res=>{
          if(res.data.error){
            this.$toasted.show(res.data.message, {type:'error'})
          }else{
            this.$toasted.show(res.data.message, {type:'success'})
          }
          this.createDialog =false
          this.getData()
        }
      )
    },
    update(){
      var formData = new FormData()
      formData.append('date_start',this.scheduleOffData.date[0])
      formData.append('date_end',this.scheduleOffData.date[1])
      formData.append('title',this.scheduleOffData.title)
      var imagefile = document.querySelector('#image');
      formData.append("image", imagefile.files[0]);

      this.$toasted.show('Please Wait...', {type:'success'})
      window.axios.post('/schedule/off/update/'+this.scheduleOffData.id,
        formData
      ,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      ).then(
        res=>{
          if(res.data.error){
            this.$toasted.show(res.data.message, {type:'error'})
          }else{
            this.$toasted.show(res.data.message, {type:'success'})
          }
          this.createDialog =false
          this.getData()
        }
      )
    },
    deletes(){
      var formData = new FormData()
      formData.append('date_start',this.scheduleOffData.date[0])
      formData.append('date_end',this.scheduleOffData.date[1])
      formData.append('title',this.scheduleOffData.title)
      var imagefile = document.querySelector('#image');
      formData.append("image", imagefile.files[0]);

      this.$toasted.show('Please Wait...', {type:'success'})
      window.axios.post('/schedule/off/delete/'+this.scheduleOffData.id,
        formData
      ,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      ).then(
        res=>{
          if(res.data.error){
            this.$toasted.show(res.data.message, {type:'error'})
          }else{
            this.$toasted.show(res.data.message, {type:'success'})
          }
          this.createDialog =false
          this.getData()
        }
      )
    },
    fetchEvents () {
      const events = []
      const eventCount = this.resultOff.length
      console.log(eventCount)
      for (let i = 0; i < eventCount; i++) {
        var date = new Date()
        var year = date.getFullYear().toString()
        console.log('y',this.now.toString())
        const first = new Date(this.date.toString()+'T00:00:00')
        const second = new Date(this.date.toString()+'T23:59:00')

        events.push({
          name: this.resultOff[i].title,
          start: first,
          end: second,
          color: 'red',
          timed: true,
          category:'Off Day',
          object:this.resultOff[i],
        })
      }

      this.events = events
    },
    rnd (a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },
    openEventDialog({event}){
        this.scheduleOffDetailDialog = true
        this.scheduleOffData=event.object
        this.scheduleOffData.date=[]
        this.scheduleOffData.date[0]=event.object.date_start
        this.scheduleOffData.date[1]=event.object.date_end
        console.log(this.scheduleOffData)
    }
  },
  mounted(){
    this.apiUrl = process.env.VUE_APP_API_URL
    this.getData()
    this.dataLoading=false
  }
}
</script>

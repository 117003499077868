<template>
  <basic-layout>
    <!--CREATE DIALOG -->
    <v-dialog
        v-model="createDialog"
        width="800"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Detail Topic
          </v-card-title>

          <v-card-text class="pa-5">
            <v-container>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="selectedData.topic"
                    counter
                    :rules="[rules.required]"
                    label="Topic"
                    readonly
                    class="fill-width"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="selectedData.count_like"
                    counter
                    :rules="[rules.required]"
                    label="Count Likes"
                    class="fill-width"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    :value="commentLength"
                    counter
                    :rules="[rules.required]"
                    label="Count Comment"
                    class="fill-width"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="selectedData.takedown"
                    counter
                    :rules="[rules.required]"
                    label="Takedown"
                    class="fill-width"
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row
              >
                <v-col>
                    <v-avatar color="red red--after">
                      <img :src="selectedData.user == undefined ? '' : selectedData.user.photo" alt="">
                    </v-avatar>
                  <v-text-field
                    :value="selectedData.user == undefined ? '' : selectedData.user.username"
                    counter
                    :rules="[rules.required]"
                    label="User"
                    class="fill-width"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <!-- COMMENT TABLE -->
                  <v-data-table
                    :headers="headersComment"
                    :items="selectedData.comment"
                    item-key="name"
                    class="elevation-1"
                    :search="search"
                    :loading=dataLoading
                  >
                    <template v-slot:top>
                      <v-container>
                        <v-row>
                          <v-col>
                            Comment List
                          </v-col>
                        </v-row>
                      </v-container>

                    </template>
                    <template v-slot:item="{ item}">
                      <tr>
                        <td>
                          {{item.comment }}
                        </td>
                        <td>
                          {{item.user.full_name}}
                        </td>
                        <td>
                          {{item.takedown }}
                        </td>
                        <td>
                          <v-btn icon color="black" outlined
                          class="mx-1"
                          v-if="item.takedown == 0"
                          @click="takedownComment(item);"
                          >
                            <v-icon>mdi mdi-bandage</v-icon>
                          </v-btn>
                          <v-btn icon color="black" outlined
                          class="mx-1"
                          @click="untakedownComment(item);"
                          v-else
                          >
                            <v-icon>mdi mdi-bandage</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </template>

                  </v-data-table>
                </v-col>
              </v-row>


            </v-container>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="red"
              text
              v-if="isCreate"
              @click="closeCreateDialog()"
            >
              Close
            </v-btn>
            <v-btn
              color="red"
              text
              v-else
              @click="closeUpdateDialog()"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
<!-- ENDDIALOG -->
    <v-container fluid class=""
    >
      <v-data-table
        :headers="headers"
        :items="data"
        item-key="name"
        class="elevation-1"
        :search="search"
        :loading=dataLoading
      >
        <template v-slot:top>
          <v-container>
            <v-row>
              <v-col>
              <v-btn :color="isHot ? 'yellow':'gray'" @click="isHot = !isHot">Hot Topic</v-btn>
              </v-col>
            </v-row>
          </v-container>

        </template>
        <template v-slot:item="{ item}">
          <tr>
            <td>
              {{item.topic }}
            </td>
            <td>
              {{item.user.full_name}}
            </td>
            <td>
              {{item.takedown }}
            </td>
            <td v-if="!isHot">
              <v-btn icon color="black" outlined
              class="mx-1"
              @click="openUpdateDialog(item);"
              >
                <v-icon>mdi mdi-eye</v-icon>
              </v-btn>
              <v-btn icon color="black" outlined
              class="mx-1"
              v-if="item.takedown == 0"
              @click="takedownForum(item);"
              >
                <v-icon>mdi mdi-bandage</v-icon>
              </v-btn>
              <v-btn icon color="black" outlined
              class="mx-1"
              v-else
              @click="untakedownForum(item);"
              >
                <v-icon>mdi mdi-bandage</v-icon>
              </v-btn>
              <v-btn icon color="black" outlined
              class="mx-1"
              @click="addToHot(item);"
              >
                <v-icon>mdi mdi-fire</v-icon>
              </v-btn>
            </td>
            <td
            v-else
            >
              <v-btn icon color="black" outlined
              class="mx-1"
              @click="removeHot(item);"
              >
                <v-icon>mdi mdi-bandage</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>

      </v-data-table>
    </v-container>
  </basic-layout>
</template>

<script>
// @ is an alias to /src
import BasicLayout from '@/components/BasicLayout.vue'
export default {
  name: 'HomeView',
  components: {
    BasicLayout
  },
  data () {
    return {
      apiUrl:'',
      commentLength:0,
      isHot :false,
      isCreate:false,
      dataLoading: true,
      search: '',
      selectedData: {},
      createDialog: false,
      data: [
      ],
      category: 'en',
      dataPackage: [
      ],
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters',
        emailMatch: () => (`The email and password you entered don't match`),
      },
    }
  },computed:{
    headers () {
        return [
          {
            text: 'Topic',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          {
            text: 'User',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          {
            text: 'Status',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          {
            text: 'Action',
            align: 'start',
            sortable: false,
          },
        ]
    },
    headersComment () {
        return [
          {
            text: 'Comment',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          {
            text: 'User',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          {
            text: 'Takedown',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          {
            text: 'Action',
            align: 'start',
            sortable: false,
          },
        ]
    }
  },
  watch: {
    // whenever question changes, this function will run
    isHot: function (a, b) {
      this.getData()
    }
  },
  methods:{
    addToHot(item){
      window.axios.post('/forum/topic/hot/add',{
        topic_id : item.id
      }).then(
        res=>{
          if(res.data.error){
            this.$toasted.show(res.data.message, {type:'error'})
          }else{
            this.$toasted.show(res.data.message, {type:'success'})
          }
          this.getData()
        }
      )
    },
    removeHot(item){
      window.axios.post('/forum/topic/hot/remove',{
        topic_id : item.id
      }).then(
        res=>{
          if(res.data.error){
            this.$toasted.show(res.data.message, {type:'error'})
          }else{
            this.$toasted.show(res.data.message, {type:'success'})
          }
          this.getData()
        }
      )
    },
    takedownForum(item){
      window.axios.post('/forum/topic/takedown',{
        topic_id : item.id
      }).then(
        res=>{
          if(res.data.error){
            this.$toasted.show(res.data.message, {type:'error'})
          }else{
            this.$toasted.show(res.data.message, {type:'success'})
          }
          this.getData()
        }
      )
    },
    untakedownForum(item){
      window.axios.post('/forum/topic/untakedown',{
        topic_id : item.id
      }).then(
        res=>{
          if(res.data.error){
            this.$toasted.show(res.data.message, {type:'error'})
          }else{
            this.$toasted.show(res.data.message, {type:'success'})
          }
          this.getData()
        }
      )
    },
    takedownComment(item){
      window.axios.post('/forum/comment/takedown',{
        comment_id : item.id
      }).then(
        res=>{
          if(res.data.error){
            this.$toasted.show(res.data.message, {type:'error'})
          }else{
            this.$toasted.show(res.data.message, {type:'success'})
          }
          this.openUpdateDialog(this.selectedData)
        }
      )
    },
    untakedownComment(item){
      window.axios.post('/forum/comment/untakedown',{
        comment_id : item.id
      }).then(
        res=>{
          if(res.data.error){
            this.$toasted.show(res.data.message, {type:'error'})
          }else{
            this.$toasted.show(res.data.message, {type:'success'})
          }
          this.openUpdateDialog(this.selectedData)
        }
      )
    },
    closeCreateDialog(){
      this.createDialog = false
      this.selectedData={}
      this.isCreate =false
    },
    closeUpdateDialog(){
      this.createDialog = false ;
      this.selectedData={}
    },
    getData(){
      let loader = this.$loading.show({
        // Optional parameters
        container: null,
      });
      var url
      if(this.isHot){
        url = '/forum/topic/hot'
      }else{
        url = '/forum/topic/admin'
      }
      window.axios.get(url).then(
        res=>{
          this.data = res.data.data
          this.dataLoading=false
          loader.hide()
        }
      )
    },
    selectItem(item){
      this.selectedData = item
      console.log(this.selectedData)
    },
    openUpdateDialog(item){
      let loader = this.$loading.show({
        // Optional parameters
        container: null,
      });
      this.selectedData = item
      window.axios.get('/forum/topic/detail/admin/'+item.id).then(
        res=>{
          console.log(res)
          this.selectedData = res.data.data
          this.commentLength = res.data.data.comment.length
          this.dataLoading=false
          this.createDialog = true ;
          loader.hide()
        }
      )
    },
  },
  mounted(){
    this.apiUrl = process.env.VUE_APP_API_URL
    this.getData()
  }
}
</script>

<template>
  <v-app id="app">
    <router-view/>
  </v-app>
</template>
<script>
window.axios = require('axios')
export default {
  /* eslint-disable */
  name: 'App',
  computed:{
    isLoggedIn : function(){ return this.$store.getters.isAuthenticated},
    userDetail : function(){ return this.$store.getters.StateUser},
  },
  beforeMount(){
   window.axios.defaults.headers.common['Authorization'] = `Bearer ${this.userDetail.access_token}` 
  },
  mounted(){
    if(this.$route.name == 'Login'){
      if(this.isLoggedIn){
        this.$router.push({name:'Dashboard'})
      }
    }else{
      if(!this.isLoggedIn){
        this.$router.push({name:'Login'})
      }
    }
  }
}
</script>

<template>
  <basic-layout>
    <!-- CREATE DIALOG -->
    <v-dialog
        v-model="createDialog"
        width="800"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            FAQ
          </v-card-title>

          <v-card-text class="pa-5">
            <div>
              <v-textarea
                outlined
                name="input-7-4"
                label="Question"
                v-model="selectedData.question"
              ></v-textarea>
            </div>
            <div>
              <v-textarea
                outlined
                name="input-7-4"
                label="Answer"
                v-model="selectedData.answer"
              ></v-textarea>
            </div>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red"
              text
              @click="create()"
            >
              Save
            </v-btn>
            <v-btn
              color="red"
              text
              @click="createDialog = false ;selectedData={}"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <!-- ENDDIALOG -->
    <v-dialog
        v-model="updateDialog"
        width="800"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            FAQ
          </v-card-title>

          <v-card-text class="pa-5">
            <div>
              <v-textarea
                outlined
                name="input-7-4"
                label="Question"
                v-model="selectedData.question"
              ></v-textarea>
            </div>
            <div>
              <v-textarea
                outlined
                name="input-7-4"
                label="Answer"
                v-model="selectedData.answer"
              ></v-textarea>
            </div>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red"
              text
              @click="update()"
            >
              Save
            </v-btn>
            <v-btn
              color="red"
              text
              @click="updateDialog = false ;selectedData={}"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <!-- ENDDIALOG -->
    <!-- UPDATE DIALOG -->

    <v-container fluid class=""
    >
      <v-data-table
        :headers="headers"
        :items="data"
        item-key="name"
        class="elevation-1"
        :search="search"
        :loading=dataLoading
      >
        <template v-slot:top>
          <v-container>
            <v-row>
              <v-col>
                <v-btn color="red" @click="createDialog = true">CREATE</v-btn>
              </v-col>
            </v-row>
          </v-container>
          <v-text-field
            v-model="search"
            label="Search"
            class="mx-4"
          ></v-text-field>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>{{item.question}}</td>
            <td>{{item.answer}}</td>
            <td>
              <v-btn icon color="black" outlined
              @click="openUpdateDialog(item)"
              >
                <v-icon>mdi mdi-eye</v-icon>
              </v-btn>
              <v-btn icon color="black" outlined
              @click="deleteFaq(item)"
              >
                <v-icon>mdi mdi-delete</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>

      </v-data-table>
    </v-container>
  </basic-layout>
</template>

<script>
// @ is an alias to /src
import BasicLayout from '@/components/BasicLayout.vue'
export default {
  name: 'HomeView',
  components: {
    BasicLayout
  },
  data () {
    return {
      dataLoading: true,
      search: '',
      data: [
      ],
      createDialog : false,
      updateDialog : false,
      selectedData: {}
    }
  },computed:{
    headers () {
        return [
          {
            text: 'Question',
            align: 'start',
            sortable: true,
            value: 'question',
          },
          {
            text: 'Answer',
            align: 'start',
            sortable: true,
            value: 'answer',
          },
          {
            text: 'Action',
            align: 'start',
            sortable: false,
          },
        ]
    }
  },
  methods:{
    getData(){
      window.axios.get('/guest/faq').then(
        res=>{
          console.log(res)
          this.data = res.data.data
          this.dataLoading=false
        }
      )
    },
    deleteFaq(item){
      window.axios.post('/faq/delete/'+item.id).then(
        res=>{
          if(res.data.error){
            this.$toasted.show(res.data.message, {type:'error'})
          }else{
            this.$toasted.show(res.data.message, {type:'success'})
          }
          this.getData()
        }
      )
    },
    create(){
      window.axios.post('/faq/create',{
        question: this.selectedData.question,
        answer: this.selectedData.answer
      }).then(
        res=>{
          if(res.data.error){
            this.$toasted.show(res.data.message, {type:'error'})
          }else{
            this.$toasted.show(res.data.message, {type:'success'})
          }
          this.createDialog =false
          this.getData()
        }
      )
    },
    update(){
      window.axios.post('/faq/update/'+this.selectedData.id,{
        question: this.selectedData.question,
        answer: this.selectedData.answer
      }).then(
        res=>{
          if(res.data.error){
            this.$toasted.show(res.data.message, {type:'error'})
          }else{
            this.$toasted.show(res.data.message, {type:'success'})
          }
          this.updateDialog =false
          this.getData()
        }
      )
    },
    openUpdateDialog(item){
      this.selectedData = item
      this.updateDialog = true;
    }
  },
  mounted(){
    this.getData()
    this.dataLoading=false
  }
}
</script>

<template>
  <v-container fluid class="grey lighten-5 ma-0 pa-0 fill-height">
    <v-btn icon class="elevation-5" color="black" x-large outlined
    style="position:fixed;top:10px;right:10px;z-index:10;"
    >
      <div
      @click="$router.push({path:'/chat'})">
      <v-icon>mdi mdi-chat
      </v-icon>
      <v-badge
      bottom
      offset-x="10"
      offset-y="-10"
      content="1"
      ></v-badge>
      </div>
    </v-btn>
    <v-row no-gutters class="fill-height">
      <v-col
        class="red fill-height ma-0 elevation-18"
        z-index="5"
        style="position:fixed;z-index:5"
        xs="3"
        sm="3"
        md="3"
        lg="3"
        xl="4"
      >
      <div class="pa-5 d-flex flex-column fill-height ">
        <div class="text text-h3">
          EC ADMIN
        </div>
        <div class="text-h6">
          Hello, {{userDetail.detail_kwikku.realname}}
        </div>
        <div class="text-h6">
          
          <v-icon
            large
            color=""
          >
            mdi-account
          </v-icon>
          {{userType.toUpperCase()}}
        </div>
        <v-btn
        class="my-5"
        rounded
        @click="logOut"
        >
        Log out
        </v-btn>
        <v-list class="pa-0" shaped
        dense
            style="background-color:#F44336;overflow-y:scroll;"
        >
          <v-list-item-group
            
          >
            <v-list-item
              @click="$router.push({path:'/home'})"
              v-if="userType=='owner' || userType=='admin'"
            >
              <v-list-item-icon
              >
                <v-icon 
                class="white--text"
                >mdi mdi-chart-donut-variant</v-icon>
              </v-list-item-icon>
              <v-list-item-content 
                class="white--text"
              >
                <v-list-item-title >Dashboard</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
          <!-- account management -->
            <v-list-group
                style="background-color:#F44336;"
                color="red darken-4"
                v-if="userType=='owner' || userType=='admin'"
            >
                <v-icon slot="prependIcon" class="white--text">mdi-account-multiple</v-icon>
                <template v-slot:activator>
                    <v-list-item-title 
                    class="white--text"
                    >Account Management</v-list-item-title>
                </template>
                <v-list-item
                    @click="$router.push({path:'/user'})"
                    v-if="userType=='owner'"
                >
                    <v-list-item-icon>
                        <v-icon class="white--text">mdi mdi-account-multiple</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="white--text"> Admin & Tutor</v-list-item-title>
                </v-list-item>
                <v-list-item
                    @click="$router.push({path:'/student'})"
                >
                    <v-list-item-icon
                    >
                        <v-icon
                        class="white--text"
                        >mdi mdi-account-multiple</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title 
                    class="white--text"
                    >Student</v-list-item-title>
                </v-list-item>
            </v-list-group>
            <!-- Course -->
            <v-list-group
                style="background-color:#F44336;"
                color="red darken-4"
                v-if="userType=='owner' || userType=='admin' || userType=='tutor'"
            >
                <v-icon slot="prependIcon" class="white--text">mdi-format-list-text</v-icon>
                <template v-slot:activator>
                    <v-list-item-title 
                    class="white--text"
                    >Course</v-list-item-title>
                </template>
                <v-list-item
                    @click="$router.push({path:'/package'})"
                >
                    <v-list-item-icon>
                        <v-icon class="white--text">mdi-format-list-text</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="white--text">Package</v-list-item-title>
                </v-list-item>
                <v-list-item
                    @click="$router.push({path:'/batch'})"
                >
                    <v-list-item-icon
                    >
                        <v-icon
                        class="white--text"
                        >mdi-format-list-text</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title 
                    class="white--text"
                    >Batch</v-list-item-title>
                </v-list-item>
                <v-list-item
                    @click="$router.push({path:'/lesson'})"
                >
                    <v-list-item-icon
                    >
                        <v-icon
                        class="white--text"
                        >mdi-format-list-text</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title 
                    class="white--text"
                    >Lesson</v-list-item-title>
                </v-list-item>

            </v-list-group>
            <!-- PAYMENT -->
            <v-list-group
                style="background-color:#F44336;"
                color="red darken-4"
                v-if="userType=='owner' || userType=='admin'"
            >
                <v-icon slot="prependIcon" class="white--text">mdi-format-list-text</v-icon>
                <template v-slot:activator>
                    <v-list-item-title 
                    class="white--text"
                    >Payment</v-list-item-title>
                </template>
                <!-- <v-list-item
                    @click="$router.push({path:'/payment'})"
                >
                    <v-list-item-icon
                    >
                        <v-icon
                        class="white--text"
                        >mdi-cash-100</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title 
                    class="white--text"
                    >Payment Type</v-list-item-title>
                </v-list-item> -->
                <v-list-item
                    @click="$router.push({path:'/order'})"
                >
                    <v-list-item-icon
                    >
                        <v-icon
                        class="white--text"
                        >mdi-cash-100</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title 
                    class="white--text"
                    >Order List</v-list-item-title>
                </v-list-item>
            </v-list-group>
            <!-- SCHEDULE -->
            <v-list-item-group
              style="background-color:#F44336;"
              v-if="userType=='owner' || userType=='admin'"
            >
              <v-list-item
                @click="$router.push({path:'/schedule'})"
              >
                <v-list-item-icon
              
                >
                  <v-icon 
                  class="white--text"
                  >mdi mdi-calendar</v-icon>
                </v-list-item-icon>

                <v-list-item-content 
                  class="white--text"
                >
                  <v-list-item-title >Schedule</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
            <!-- SCHEDULE -->
            <v-list-item-group
              style="background-color:#F44336;"
              v-if="userType=='owner' || userType=='admin'"
            >
              <v-list-item
                @click="$router.push({path:'/zoom'})"
              >
                <v-list-item-icon
              
                >
                  <v-icon 
                  class="white--text"
                  >mdi mdi-calendar</v-icon>
                </v-list-item-icon>

                <v-list-item-content 
                  class="white--text"
                >
                  <v-list-item-title >Zoom</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
            <!-- FORUM -->
            <v-list-item-group
              style="background-color:#F44336;"
              v-if="userType=='owner' || userType=='admin'"
            >
              <v-list-item
                @click="$router.push({path:'/forum'})"
              >
                <v-list-item-icon
              
                >
                  <v-icon 
                  class="white--text"
                  >mdi mdi-comment-quote</v-icon>
                </v-list-item-icon>

                <v-list-item-content 
                  class="white--text"
                >
                  <v-list-item-title >Forum</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
            <!-- Content Management -->
            <v-list-group
                style="background-color:#F44336;"
                color="red darken-4"
                v-if="userType=='owner' || userType=='admin'"
            >
                <v-icon slot="prependIcon" class="white--text">mdi-format-list-text</v-icon>
                <template v-slot:activator>
                    <v-list-item-title 
                    class="white--text"
                    >Content Management</v-list-item-title>
                </template>
                <v-list-item
                    @click="$router.push({path:'/banner'})"
                >
                    <v-list-item-icon>
                        <v-icon class="white--text">mdi-panorama-variant</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title class="white--text">Banner</v-list-item-title>
                </v-list-item>
                <v-list-item
                    @click="$router.push({path:'/vocab'})"
                >
                    <v-list-item-icon
                    >
                        <v-icon
                        class="white--text"
                        >mdi-comment-quote</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title 
                    class="white--text"
                    >Vocabulary</v-list-item-title>
                </v-list-item>
                <v-list-item
                    @click="$router.push({path:'/faq'})"
                >
                    <v-list-item-icon
                    >
                        <v-icon
                        class="white--text"
                        >mdi-help</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title 
                    class="white--text"
                    >Faq</v-list-item-title>
                </v-list-item>
                <v-list-item
                    @click="$router.push({path:'/about'})"
                >
                    <v-list-item-icon
                    >
                        <v-icon
                        class="white--text"
                        >mdi-comment-quote</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title 
                    class="white--text"
                    >About</v-list-item-title>
                </v-list-item>
                <v-list-item
                    @click="$router.push({path:'/main-banner'})"
                >
                    <v-list-item-icon
                    >
                        <v-icon
                        class="white--text"
                        >mdi-comment-quote</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title 
                    class="white--text"
                    >Main Banner</v-list-item-title>
                </v-list-item>
                <v-list-item
                    @click="$router.push({path:'/privacy'})"
                >
                    <v-list-item-icon
                    >
                        <v-icon
                        class="white--text"
                        >mdi-comment-quote</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title 
                    class="white--text"
                    >Privacy Policy</v-list-item-title>
                </v-list-item>
                <v-list-item
                    @click="$router.push({path:'/terms'})"
                >
                    <v-list-item-icon
                    >
                        <v-icon
                        class="white--text"
                        >mdi-comment-quote</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title 
                    class="white--text"
                    >Terms and Condition</v-list-item-title>
                </v-list-item>
                <v-list-item
                    @click="$router.push({path:'/contact'})"
                >
                    <v-list-item-icon
                    >
                        <v-icon
                        class="white--text"
                        >mdi-phone</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title 
                    class="white--text"
                    >Contact</v-list-item-title>
                </v-list-item>
            </v-list-group>
          </v-list>
      </div>
      </v-col>
      <v-col
        class="red fill-height ma-0"
        xs="3"
        sm="3"
        md="3"
        lg="3"
        xl="4"
      ></v-col>
      <v-col
        xs="9"
        sm="9"
        md="9"
        lg="9"
        xl="8"
        style=""
      >
          <div 
          style="position:fixed;top:0;width:100%;z-index:4;height:80px;" 
          class=" red px-5 py-5 text-h6 elevation-6" >
            {{$route.name}}
          </div>
          <v-container grid-list-xs style="margin-top:80px;">
            <slot/>
          </v-container>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
// @ is an alias to /src
import { mapActions } from "vuex";

export default {
  name: 'BasicLayout',
  components: {
  },
  data () {
    return {
      items: [
        {path: '/home', name: 'Dashboard',text: 'Dashboard', icon: 'mdi-chart-donut-variant' },
        {path: '#', name: 'Account Management',text: 'Account Management', icon: 'mdi-account-multiple' 
            ,subList:[
            {path: '/user',text: 'Admin & Tutor', icon: 'mdi-account-multiple' },
            {path: '/student',text: 'Student', icon: 'mdi-account-multiple' },
            ]
        },
        {path: '/package',name: 'Package',text: 'Package', icon: 'mdi-format-list-text' },
        {path: '/student',name: 'Student',text: 'Student', icon: 'mdi-account-multiple' },
        {path: '/forum',name: 'Forum',text: 'Forum', icon: 'mdi-comment-quote' },
        {path: '/faq',name: 'Faq',text: 'Faq', icon: 'mdi-comment-quote' },
        {path: '/vocab',name: 'Vocab',text: 'Vocab', icon: 'mdi-comment-quote' },
        {path: '/payment',name: 'Payment',text: 'Payment', icon: 'mdi-cash-100' },
      ],
    }
  },
  computed:{
    isLoggedIn : function(){ return this.$store.getters.isAuthenticated},
    userDetail : function(){ return this.$store.getters.StateUser},
    userType : function(){ return this.$store.getters.StateUserType},
    selectedItem (){
        var index = 0
        if(this.$route.name == 'Dashboard'){
            index = 0
        } else if(this.$route.name == 'Account Management'){
            index = 1
        } else if(this.$route.name == 'Package'){
            index = 2
        } else if(this.$route.name == 'Student'){
            index = 3
        } else if(this.$route.name == 'Forum'){
            index = 4
        } else if(this.$route.name == 'Frequently Asked Question'){
            index = 5
        } else if(this.$route.name == 'Vocabulary'){
            index = 6
        } else if(this.$route.name == 'Payment'){
            index = 7
        }

        return index
    }
  },
  methods:{
    ...mapActions(["LogOut"]),
    async logOut(){
      await this.LogOut().then(
      this.$router.push({ name: 'Login' }))
    }
  }
}
</script>

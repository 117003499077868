<template>
  <basic-layout>
    <!--CREATE Practice DIALOG -->
    <v-dialog
        v-model="createPracticeDialog"
        width="800"
        persistent
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Practice
          </v-card-title>

          <v-card-text class="pa-5">
            <v-container>
              <v-row>
                <v-col>
                  <v-textarea
                    outlined
                    name="input-7-4"
                    label="Question"
                    v-model="selectedPractice.question"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-textarea
                    outlined
                    name="input-7-4"
                    label="Answer"
                    v-model="selectedPractice.answer"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="selectedPractice.time"
                    counter
                    :rules="[rules.required]"
                    label="Time"
                    class="fill-width"
                    required
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-select
                    :items="[{value:1,text:'Word'},
                    {value:2,text:'Multi Choice'},
                    {value:3,text:'Speech Recognition'},
                    {value:4,text:'Word Stacking'},
                    {value:5,text:'Essay'}
                    ]"
                    item-text="text"
                    item-value="value"
                    label="Type"
                    v-model="selectedPractice.type_id"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-file-input
                    label="Image"
                    truncate-length="15"
                    id="image"
                  ></v-file-input>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-file-input
                    label="Audio"
                    truncate-length="15"
                    id="audio"
                  ></v-file-input>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                </v-col>
                <v-col>
                </v-col>
              </v-row>

            </v-container>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red"
              text
              @click="createPractice()"
            >
              Save
            </v-btn>
            <v-btn
              color="red"
              text
              @click="createPracticeDialog = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    <!--END CREATE Practice DIALOG -->
    <!--UPDATE Practice DIALOG -->
    <v-dialog
        v-model="updatePracticeDialog"
        width="800"
        persistent
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Practice
          </v-card-title>

          <v-card-text class="pa-5">
            <v-container>
              <v-row>
                <v-col>
                  <v-textarea
                    outlined
                    name="input-7-4"
                    label="Question"
                    v-model="selectedPractice.question"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-textarea
                    outlined
                    name="input-7-4"
                    label="Answer"
                    v-model="selectedPractice.answer"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="selectedPractice.time"
                    counter
                    :rules="[rules.required]"
                    label="Time"
                    class="fill-width"
                    required
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-select
                    :items="[{value:1,text:'Word'},
                    {value:2,text:'Multi Choice'},
                    {value:3,text:'Speech Recognition'},
                    {value:4,text:'Word Stacking'},
                    {value:5,text:'Essay'}
                    ]"
                    item-text="text"
                    item-value="value"
                    label="Type"
                    v-model="selectedPractice.type_id"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row v-if="selectedPractice.url_picture != null">
                <v-col>
                  <img width="300" :src="apiUrl+'/api/guest/asset/image/practice/'+selectedPractice.url_picture" alt="">
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-file-input
                    label="Image"
                    truncate-length="15"
                    id="image"
                  ></v-file-input>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <span v-if="selectedPractice.url_audio != null">This Practice have audio</span>
                  <v-file-input
                    label="Audio"
                    truncate-length="15"
                    id="audio"
                  ></v-file-input>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                </v-col>
                <v-col>
                </v-col>
              </v-row>

            </v-container>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red"
              text
              @click="updatePractice()"
            >
              Save
            </v-btn>
            <v-btn
              color="red"
              text
              @click="updatePracticeDialog = false;selectedPractice = {}"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    <!--END CREATE Practice DIALOG -->
    <!--CREATE DIALOG -->
    <v-dialog
        v-model="createDialog"
        width="800"
        persistent
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Lesson
          </v-card-title>

          <v-card-text class="pa-5">
            <v-container>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="selectedData.title"
                    counter
                    :rules="[rules.required]"
                    label="title"
                    class="fill-width"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mb-5">
                <v-col>
                  <quill-editor
                      ref="editor"
                      v-model="selectedData.description"
                      @change="onEditorChange($event)"
                      style="height:100%;"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="selectedData.pass_score"
                    counter
                    :rules="[rules.required]"
                    label="Pass Score"
                    class="fill-width"
                    required
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="selectedData.video_url"
                    counter
                    :rules="[rules.required]"
                    label="Youtube Code"
                    class="fill-width"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-radio-group v-model="selectedData.is_pretest" label="Pretest">
                    <v-radio
                      label="yes"
                      value="1"
                    ></v-radio>
                    <v-radio
                      label="no"
                      value="0"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col>
                  <v-radio-group v-model="selectedData.is_posttest" label="Posttest">
                    <v-radio
                      label="yes"
                      value="1"
                    ></v-radio>
                    <v-radio
                      label="no"
                      value="0"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red"
              text
              @click="create()"
            >
              Save
            </v-btn>
            <v-btn
              color="red"
              text
              @click="closeCreateDialog()"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
<!-- ENDDIALOG -->
    <!--UPDATE DIALOG -->
        <v-dialog
        v-model="updateDialog"
        width="800"
        persistent
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Lesson
          </v-card-title>

          <v-card-text class="pa-5">
            <v-container>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="selectedData.title"
                    counter
                    :rules="[rules.required]"
                    label="title"
                    class="fill-width"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="mb-5">
                <v-col>
                  <quill-editor
                      ref="editor"
                      v-model="selectedData.description"
                      @change="onEditorChange($event)"
                      style="height:100%;"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="selectedData.pass_score"
                    counter
                    :rules="[rules.required]"
                    label="Pass Score"
                    class="fill-width"
                    required
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="selectedData.video_url"
                    counter
                    :rules="[rules.required]"
                    label="Youtube Code"
                    class="fill-width"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-radio-group v-model="selectedData.is_pretest" label="Pretest">
                    <v-radio
                      v-for="n in [1,0]"
                      :key="n"
                      :label="n == 1 ? `yes`:`no`"
                      :value="n"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col>
                  <v-radio-group v-model="selectedData.is_posttest" label="Posttest">
                    <v-radio
                      v-for="n in [1,0]"
                      :key="n"
                      :label="n == 1 ? `yes`:`no`"
                      :value="n"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red"
              text
              @click="update()"
            >
              Save
            </v-btn>
            <v-btn
              color="red"
              text
              @click="updateDialog = false; selectedData={}"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <!--END UPDATE DIALOG -->
<!--PRACTICE DIALOG -->
        <v-dialog
        v-model="practiceDialog"
        width="800"
        persistent
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Practice
          </v-card-title>

          <v-card-text class="pa-5">

            <v-data-table
              :headers="headersPractice"
              :items="dataPractice"
              item-key="name"
              class="elevation-1"
              :search="search"
              :loading=dataLoading
            >
              <template v-slot:top>
                <v-container>
                  <v-row>
                    <v-col>
                    <v-btn color="red" @click="openCreatePracticeDialog()">Create</v-btn>
                    </v-col>
                  </v-row>
                </v-container>

              </template>
              <template v-slot:item="{ item }">
                <tr>
                  <td>
                    {{item.type.type}}
                  </td>
                  <td>
                    {{item.question}}
                  </td>
                  <td>
                    {{item.answer}}
                  </td>
                  <td>
                    <v-btn icon color="black" outlined
                    class="mx-1"
                    @click="openUpdatePracticeDialog(item);"
                    >
                      <v-icon>mdi mdi-eye</v-icon>
                    </v-btn>
                    <v-btn icon color="black" outlined
                    class="mx-1"
                    @click="deletePractice(item);"
                    >
                      <v-icon>mdi mdi-delete</v-icon>
                    </v-btn>
                    <v-btn icon color="black" outlined
                    class="mx-1"
                    v-if="item.type_id == 2 || item.type_id == 4"
                    @click="openChoiceDialog(item);"
                    >
                      <v-icon>mdi mdi-hexagon-multiple</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>

            </v-data-table>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red"
              text
              @click="practiceDialog = false;"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <!--END PRACTICE DIALOG -->
    <!--Choice DIALOG -->
        <v-dialog
        v-model="choicePracticeDialog"
        width="800"
        persistent
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Practice Choice
          </v-card-title>

          <v-card-text class="pa-5">

            <v-data-table
              :headers="headersChoice"
              :items="dataChoice"
              item-key="name"
              class="elevation-1"
              :search="search"
              :loading=dataLoading
            >
              <template v-slot:top>
                <v-container>
                  <v-row>
                    <v-col>
                      <v-text-field
                        v-model="choice"
                        counter
                        :rules="[rules.required]"
                        label="Choice"
                        class="fill-width"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col>
                        <v-btn color="red" @click="addChoice()">Add</v-btn>
                    </v-col>
                  </v-row>
                </v-container>

              </template>
              <template v-slot:item="{ item }">
                <tr>
                  <td>
                    {{item.choice}}
                  </td>
                  <td>
                    <v-btn icon color="black" outlined
                    class="mx-1"
                    @click="deleteChoice(item);"
                    >
                      <v-icon>mdi mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>

            </v-data-table>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red"
              text
              @click="choicePracticeDialog = false;"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <!--END Choice DIALOG -->
    <v-container fluid class=""
    >
      <v-col>
        <v-select
          :items="['en','ina']"
          label="Category "
          dense
          v-model="category"
          @change="getDataPackage()"
        ></v-select>
      </v-col>
      <v-col>
        <v-select
          :items="dataPackage"
          item-text="name"
          item-value="id"
          label="Select "
          dense
          v-model="packageId"
        ></v-select>
      </v-col>
      <v-data-table
        :headers="headers"
        :items="data"
        item-key="name"
        class="elevation-1"
        :search="search"
        :loading=dataLoading
      >
        <template v-slot:top>
          <v-container>
            <v-row>
              <v-col>
              <v-btn color="red" @click="openCreateDialog">Create</v-btn>
              </v-col>
            </v-row>
          </v-container>

        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>
              {{item.title}}
            </td>
            <td>
              <v-chip v-if="item.is_pretest" >  Pretest</v-chip>
              <v-chip v-if="item.lesson != null" >{{item.lesson}}</v-chip>
              <v-chip v-if="item.is_posttest" >Posttest</v-chip>
              
            </td>
            <td>
              <v-btn icon color="black" outlined
              class="mx-1"
              @click="openUpdateDialog(item);"
              >
                <v-icon>mdi mdi-eye</v-icon>
              </v-btn>
              <v-btn icon color="black" outlined
              class="mx-1"
              @click="deleteItem(item);"
              >
                <v-icon>mdi mdi-delete</v-icon>
              </v-btn>
              <v-btn icon color="black" outlined
              class="mx-1"
              @click="openPracticeDialog(item);"
              >
                <v-icon>mdi mdi-format-list-bulleted</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>

      </v-data-table>
    </v-container>
  </basic-layout>
</template>

<script>
// @ is an alias to /src
import BasicLayout from '@/components/BasicLayout.vue'
export default {
  name: 'HomeView',
  components: {
    BasicLayout
  },
  data () {
    return {
      radioGroup: 1,
      apiUrl:'',
      isCreate:false,
      dataLoading: true,
      search: '',
      selectedData: {
        is_pretest: 1
      },
      selectedUser: {},
      selectedPractice: {},
      createDialog: false,
      updateDialog: false,
      practiceDialog: false,
      createPracticeDialog:false,
      updatePracticeDialog:false,
      choicePracticeDialog:false,
      choice:'',
      data: [
      ],
      category: 'en',
      packageId: 0,
      dataPackage: [
      ],
      dataPractice: [
      ],
      dataSchedule: [
      ],
      dataChoice: [
      ],
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters',
        emailMatch: () => (`The email and password you entered don't match`),
      },
    }
  },computed:{
    headers () {
        return [
          {
            text: 'Name',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          {
            text: 'Lesson',
            align: 'start',
            sortable: true,
            value: 'label',
          },
          {
            text: 'Action',
            align: 'start',
            sortable: false,
          },
        ]
    },
    headersPractice () {
        return [
          {
            text: 'Type',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          {
            text: 'Question',
            align: 'start',
            sortable: true,
            value: 'tutor_id',
          },
          {
            text: 'Answer',
            align: 'start',
            sortable: false,
          },
          {
            text: 'Action',
            align: 'start',
            sortable: false,
          },
        ]
    },
    headersChoice () {
        return [
          {
            text: 'Choice',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          {
            text: 'Action',
            align: 'start',
            sortable: false,
          },
        ]
    }
  },
  watch: {
    // whenever question changes, this function will run
    packageId: function () {
      this.getData()
    }
  },
  methods:{
    onEditorChange({ quill, html, text,source}) {
      console.log('editor change!', quill, html, text,source)
      this.selectedData.description = html
    },
    reorder(type,id){
      window.axios.post('/banner/reorder/'+id,{
        type: type,
      }).then(
        res=>{
          if(res.data.error){
            this.$toasted.show(res.data.message, {type:'error'})
          }else{
            this.$toasted.show(res.data.message, {type:'success'})
          }
          this.getData()
        }
      )
    },
    addChoice(){
      var formData = new FormData()
      formData.append('question_id',this.selectedPractice.id)
      formData.append("choice", this.choice);

      window.axios.post('/practice/choice/create',formData,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(
        res=>{
          console.log(res)
          if(res.data.error){
            this.$toasted.show(res.data.message, {type:'error'})
          }else{
            this.$toasted.show(res.data.message, {type:'success'})
          }
          this.openPracticeDialog(this.selectedData)
          this.choicePracticeDialog = false
          this.choice=''
        }
      )
    },
    deleteChoice(item){
      window.axios.post('/practice/choice/delete/'+item.id,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(
        res=>{
          console.log(res)
          if(res.data.error){
            this.$toasted.show(res.data.message, {type:'error'})
          }else{
            this.$toasted.show(res.data.message, {type:'success'})
          }
          this.openPracticeDialog(this.selectedData)
          this.choicePracticeDialog = false
          this.choice=''
        }
      )
    },
    deleteItem(item){
      window.axios.post('/lesson/delete/'+item.id).then(
        res=>{
          if(res.data.error){
            this.$toasted.show(res.data.message, {type:'error'})
          }else{
            this.$toasted.show(res.data.message, {type:'success'})
          }
          this.getData()
        }
      )
    },
    createPractice(){
      var formData = new FormData()
      formData.append('lesson_id',this.selectedData.id)
      formData.append('type_id',this.selectedPractice.type_id)
      formData.append("question", this.selectedPractice.question);
      formData.append('answer',this.selectedPractice.answer)
      formData.append('time',this.selectedPractice.time)

      var imagefile = document.querySelector('#image');
      formData.append('image',imagefile.files[0])
      var audiofile = document.querySelector('#audio');
      formData.append('audio',audiofile.files[0])

      window.axios.post('/practice/create',formData,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(
        res=>{
          console.log(res)
          if(res.data.error){
            this.$toasted.show(res.data.message, {type:'error'})
          }else{
            this.$toasted.show(res.data.message, {type:'success'})
          }
          this.createPracticeDialog = false
          this.selectedPractice = {}
          this.openPracticeDialog(this.selectedData)
        }
      )
    },
    updatePractice(){
      var formData = new FormData()
      formData.append('lesson_id',this.selectedData.id)
      formData.append('type_id',this.selectedPractice.type_id)
      formData.append("question", this.selectedPractice.question);
      formData.append('answer',this.selectedPractice.answer)
      formData.append('time',this.selectedPractice.time)

      var imagefile = document.querySelector('#image');
      formData.append('image',imagefile.files[0])
      var audiofile = document.querySelector('#audio');
      formData.append('audio',audiofile.files[0])

      window.axios.post('/practice/update/'+this.selectedPractice.id,formData,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(
        res=>{
          console.log(res)
          if(res.data.error){
            this.$toasted.show(res.data.message, {type:'error'})
          }else{
            this.$toasted.show(res.data.message, {type:'success'})
          }
          this.updatePracticeDialog = false
          this.selectedPractice = {}
          this.openPracticeDialog(this.selectedData)
        }
      )
    },
    deletePractice(item){
      window.axios.post('/practice/delete/'+item.id,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(
        res=>{
          console.log(res)
          if(res.data.error){
            this.$toasted.show(res.data.message, {type:'error'})
          }else{
            this.$toasted.show(res.data.message, {type:'success'})
          }
          this.updatePracticeDialog = false
          this.selectedPractice = {}
          this.openPracticeDialog(this.selectedData)
        }
      )
    },
    create(){
      var formData = new FormData()
      formData.append('packet_id',this.packageId)
      formData.append("title", this.selectedData.title);
      formData.append('pass_score',this.selectedData.pass_score)
      formData.append('description',this.selectedData.description)
      formData.append('video_url',this.selectedData.video_url)
      formData.append('is_pretest',this.selectedData.is_pretest)
      formData.append('is_posttest',this.selectedData.is_posttest)


      window.axios.post('/lesson/create',formData,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(
        res=>{
          console.log(res)
          if(res.data.error){
            this.$toasted.show(res.data.message, {type:'error'})
          }else{
            this.$toasted.show(res.data.message, {type:'success'})
          }
          this.getData()
          this.createDialog = false
        }
      )
    },
    update(){
      var formData = new FormData()
      formData.append('packet_id',this.packageId)
      formData.append("title", this.selectedData.title);
      formData.append('pass_score',this.selectedData.pass_score)
      formData.append('description',this.selectedData.description)
      formData.append('video_url',this.selectedData.video_url)
      formData.append('is_pretest',this.selectedData.is_pretest)
      formData.append('is_posttest',this.selectedData.is_posttest)

      window.axios.post('/lesson/update/'+this.selectedData.id,formData,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(
        res=>{
          console.log(res)
          if(res.data.error){
            this.$toasted.show(res.data.message, {type:'error'})
          }else{
            this.$toasted.show(res.data.message, {type:'success'})
          }
          this.getData()
          this.createDialog = false
        }
      )
    },
    closeCreateDialog(){
      this.createDialog = false
      this.selectedData={}
    },
    closeUpdateDialog(){
      this.createDialog = false ;
      this.selectedData={}
    },
    getData(){
      this.dataLoading=true
      window.axios.get('/lesson?packet_id='+this.packageId).then(
        res=>{
          console.log(res)
          this.data = res.data.data
          this.dataLoading=false
        }
      )
    },
    getDataPackage(){
      window.axios.get('/package/admin?category='+this.category).then(
        res=>{
          this.dataPackage = res.data.data
        }
      )
    },
    getDataTutor(){
      window.axios.get('/user/tutor/get').then(
        res=>{
          this.dataTutor = res.data.data
        }
      )
    },
    selectItem(item){
      this.selectedData = item
      console.log(this.selectedData)
    },
    openCreateDialog(){
      this.isCreate =true
      this.selectedData ={}
      this.createDialog = true ;
    },
    openUpdateDialog(item){
      this.selectedData =item
      this.updateDialog = true ;
    },
    openPracticeDialog(item){
        window.axios.get('/practice?lesson_id='+item.id).then(
        res=>{
          console.log(res)
          this.dataPractice = res.data.data
        }
      )
      this.selectedData = item
      this.practiceDialog = true ;
    },
    openCreatePracticeDialog(item){
      // this.selectedPractice = item.userList
      this.createPracticeDialog = true ;
    },
    openUpdatePracticeDialog(item){
      this.selectedPractice = item
      this.updatePracticeDialog = true ;
    },
    openChoiceDialog(item){
      this.selectedPractice =item 
      if(item.type_id == 2){
      this.dataChoice = item.choice
      }else{
      this.dataChoice = item.word_list
      }
      this.choicePracticeDialog =true;
    }
  },
  mounted(){
    this.dataLoading=false
    this.apiUrl = process.env.VUE_APP_API_URL
    this.getDataPackage()
    this.getDataTutor()

  }
}
</script>

<template>
  <basic-layout>
    <v-container fluid class=""
    >
      <v-text-field
        v-model="data[0].value"
        counter
        label="Whatsapp"
        class="fill-width"
        required
      ></v-text-field>
      <v-btn color="success" @click="save">Save</v-btn>
    </v-container>
  </basic-layout>
</template>

<script>
// @ is an alias to /src
import BasicLayout from '@/components/BasicLayout.vue'
export default {
  name: 'HomeView',
  components: {
    BasicLayout
  },
  data () {
    return {
      dataLoading: true,
      search: '',
      data: [
      ],
      createDialog : false,
      updateDialog : false,
      selectedData: {}
    }
  },computed:{
    headers () {
        return [
          {
            text: 'Question',
            align: 'start',
            sortable: true,
            value: 'question',
          },
          {
            text: 'Answer',
            align: 'start',
            sortable: true,
            value: 'answer',
          },
          {
            text: 'Action',
            align: 'start',
            sortable: false,
          },
        ]
    }
  },
  methods:{
    getData(){
      window.axios.get('/guest/contact').then(
        res=>{
          console.log(res)
          this.data = res.data.data
          this.dataLoading=false
        }
      )
    },
    save(){
      this.$toasted.show('Coming soon...', {type:'success'})
      // window.axios.post('/home/content/update/term',{
      //   content: this.data,
      // }).then(
      //   res=>{
      //     if(res.data.error){
      //       this.$toasted.show(res.data.message, {type:'error'})
      //     }else{
      //       this.$toasted.show(res.data.message, {type:'success'})
      //     }
      //     this.createDialog =false
      //     this.getData()
        // }
      // )
    },
  },
  mounted(){
    this.getData()
    this.dataLoading=false
  }
}
</script>

<template>
  <basic-layout>
    <!--CREATE DIALOG -->
    <v-dialog
        v-model="createDialog"
        width="500"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Detail Vocab
          </v-card-title>

          <v-card-text class="pa-5">
            <v-container>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="selectedData.word"
                    counter
                    :rules="[rules.required]"
                    label="Word"
                    class="fill-width"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="selectedData.v1"
                    counter
                    :rules="[rules.required]"
                    label="V1"
                    class="fill-width"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="selectedData.v2"
                    counter
                    :rules="[rules.required]"
                    label="V2"
                    class="fill-width"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row
              >
                <v-col>
                  <v-text-field
                    v-model="selectedData.v3"
                    counter
                    :rules="[rules.required]"
                    label="V3"
                    class="fill-width"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red"
              text
              v-if="isCreate"
              @click="create()"
            >
              Save
            </v-btn>
            <v-btn
              color="red"
              text
              v-if="isCreate"
              @click="closeCreateDialog()"
            >
              Close
            </v-btn>
            <v-btn
              color="red"
              text
              v-else
              @click="closeUpdateDialog()"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
<!-- ENDDIALOG -->
    <v-container fluid class=""
    >
      <v-data-table
        :headers="headers"
        :items="data"
        item-key="name"
        class="elevation-1"
        :search="search"
        :loading=dataLoading
      >
        <template v-slot:top>
          <v-container>
            <v-row>
              <v-col>
              <v-btn color="red" @click="openCreateDialog">Create</v-btn>
              </v-col>
            </v-row>
          </v-container>

        </template>
        <template v-slot:item="{ item}">
          <tr>
            <td>
              {{item.word }}
            </td>
            <td>
              {{item.v1 }}
            </td>
            <td>
              {{item.v2 }}
            </td>
            <td>
              {{item.v3 }}
            </td>
            <td>
              <v-btn icon color="black" outlined
              class="mx-1"
              @click="deleteItem(item);"
              >
                <v-icon>mdi mdi-delete</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>

      </v-data-table>
    </v-container>
  </basic-layout>
</template>

<script>
// @ is an alias to /src
import BasicLayout from '@/components/BasicLayout.vue'
export default {
  name: 'HomeView',
  components: {
    BasicLayout
  },
  data () {
    return {
      apiUrl:'',
      isCreate:false,
      dataLoading: true,
      search: '',
      selectedData: {},
      createDialog: false,
      data: [
      ],
      category: 'en',
      dataPackage: [
      ],
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters',
        emailMatch: () => (`The email and password you entered don't match`),
      },
    }
  },computed:{
    headers () {
        return [
          {
            text: 'Word',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          {
            text: 'V1',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          {
            text: 'V2',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          {
            text: 'V3',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          {
            text: 'Action',
            align: 'start',
            sortable: false,
          },
        ]
    }
  },
  methods:{
    reorder(type,id){
      window.axios.post('/banner/reorder/'+id,{
        type: type,
      }).then(
        res=>{
          if(res.data.error){
            this.$toasted.show(res.data.message, {type:'error'})
          }else{
            this.$toasted.show(res.data.message, {type:'success'})
          }
          this.getData()
        }
      )
    },
    deleteItem(item){
      window.axios.post('/vocabulary/delete/'+item.id).then(
        res=>{
          if(res.data.error){
            this.$toasted.show(res.data.message, {type:'error'})
          }else{
            this.$toasted.show(res.data.message, {type:'success'})
          }
          this.getData()
        }
      )
    },
    create(){
      if(this.selectedData.content_id == undefined){
        this.selectedData.content_id =null
      }
      var formData = new FormData()
      var imagefile = document.querySelector('#image');
      formData.append("word", this.selectedData.word);
      formData.append('v1',this.selectedData.v1)
      formData.append('v2',this.selectedData.v2)
      formData.append('v3',this.selectedData.v3)

      window.axios.post('/vocabulary/create',formData,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(
        res=>{
          console.log(res)
          if(res.data.error){
            this.$toasted.show(res.data.message, {type:'error'})
          }else{
            this.$toasted.show(res.data.message, {type:'success'})
          }
          this.getData()
          this.createDialog = false
        }
      )
    },
    closeCreateDialog(){
      this.createDialog = false
      this.selectedData={}
      this.isCreate =false
    },
    closeUpdateDialog(){
      this.createDialog = false ;
      this.selectedData={}
    },
    getData(){
      window.axios.get('/guest/vocabulary').then(
        res=>{
          this.data = res.data.data
          this.dataLoading=false
        }
      )
    },
    selectItem(item){
      this.selectedData = item
      console.log(this.selectedData)
    },
    openCreateDialog(){
      this.isCreate =true
      this.selectedData ={}
      this.createDialog = true ;
    },
  },
  mounted(){
    this.apiUrl = process.env.VUE_APP_API_URL
    this.getData()
  }
}
</script>

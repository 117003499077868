<template>
  <basic-layout>
    <v-container fluid class=""
    >
      <v-data-table
        :headers="headers"
        :items="desserts"
        item-key="name"
        class="elevation-1"
        :search="search"
        :loading=dataLoading
      >
        <template v-slot:top>
          <v-text-field
            v-model="search"
            label="Search"
            class="mx-4"
          ></v-text-field>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>{{item.name}}</td>
            <td>{{item.username}}</td>
            <td>{{item.packageCategory + '|' + item.packageName}}</td>
            <td>{{item.status}}</td>
            <td>
              <v-btn icon color="black" outlined>
                <v-icon @click="openDetail(item)">mdi mdi-eye</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>

      </v-data-table>

      <!-- DIALOG -->
      <v-dialog
        v-model="dialogDetail"
        width="60%"
        persistent
      >
        <v-card>
          <v-card-title class="text-h6 red">
            Detail Payment
          </v-card-title>

          <v-card-text>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red"
              text
              @click="dialogDetail = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </basic-layout>
</template>

<script>
// @ is an alias to /src
import BasicLayout from '@/components/BasicLayout.vue'
export default {
  name: 'HomeView',
  components: {
    BasicLayout
  },
  data () {
    return {
      dialogDetail:false,
      dataLoading: true,
      search: '',
        calories: '',
        desserts: [
          {
            id: 1,
            name: 'Lius Alviando',
            username: 'liusalviando',
            packageName: 'Self Learning',
            packageCategory: 'EN',
            status: 'Success',
          },
        ],
    }
  },computed:{
    headers () {
        return [
          {
            text: 'Name',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          {
            text: 'Username',
            align: 'start',
            sortable: true,
            value: 'username',
          },
          {
            text: 'Package',
            align: 'start',
            sortable: true,
            value: 'packageName',
          },
          {
            text: 'Status',
            align: 'start',
            sortable: true,
            value: 'status',
          },
          {
            text: 'Action',
            align: 'start',
            sortable: false,
          },
        ]
    }
  },
  methods:{
    openDetail (item) {
      console.log(item)
      this.dialogDetail=true;
    }
  },
  mounted(){
    this.dataLoading=false
  }
}
</script>

import axios from 'axios';
const state = {
    user: null,
    zoomToken: null,
    userType: null,
};
const getters = {
    isAuthenticated: state => !!state.user,    
    StateUser: state => state.user,
    StateUserType :state => state.userType,
    StateZoomToken :state => state.zoomToken,

};
const actions = {
    LogIn({commit}, {User,type}) {
        return new Promise((resolve, reject) => {
        axios.post('/login/'+type, User).then(
            res => {
                console.log('a',type)
                if(!res.data.error){
                    resolve(res)
                    commit('setUser',res.data.data)
                    commit('setUserType',type)
                    commit('setZoomToken',res.data.data.user.zoom_token)
                }else{
                    reject(res.data.code);
                }
            }
        ).catch(error =>{
            reject(error);
        })
        })
    },
    async LogOut({commit}){
        let user = null
        commit('LogOut', user)
    },
    async setZoomToken({commit},token){
        commit('setZoomToken', token)
    }
};
const mutations = {
    setUser(state, username){
        state.user = username
    },
    setZoomToken(state, token){
        state.zoomToken = token
    },
    setUserType(state, type){
        state.userType = type
    },
    LogOut(state){
        state.user = null
    },
};
export default {
  state,
  getters,
  actions,
  mutations
};
<template>
  <basic-layout>
    <v-container fluid class=""
    >
    <v-row class="">
      <v-col
        class="fill-height ma-0"
        z-index="1"
        style=""
        cols="4"
      >
        <v-card class="elevation-10">
          <v-card-title primary-title>
            <v-icon>mdi mdi-account</v-icon>
            Student
          </v-card-title>
          <v-card-text>
            <p><b>Total</b></p>
            <p>{{studentCount}}</p>
            <p><b>Passive</b></p>
            <p>{{passiveCount}}</p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        class="fill-height ma-0"
        z-index="1"
        style=""
        cols="4"
      >
        <v-card class="elevation-10">
          <v-card-title primary-title>
            <v-icon>mdi mdi-account</v-icon>
            Tutor
          </v-card-title>
          <v-card-text>
            {{dataTutor.length}}
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        class="fill-height ma-0"
        z-index="1"
        style=""
        cols="4"
      >
        <v-card class="elevation-10">
          <v-card-title primary-title>
            <v-icon>mdi mdi-comment-quote</v-icon>
            Total Topic
          </v-card-title>
          <v-card-text>
            {{dataForum.length}}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    </v-container>
  </basic-layout>
</template>

<script>
// @ is an alias to /src
import BasicLayout from '@/components/BasicLayout.vue'
import ZoomMtgEmbedded from '@zoomus/websdk/embedded';

import { axios } from 'axios';

export default {
  name: 'HomeView',
  components: {
    BasicLayout
  },
  data (){
    return{
      studentCount:'please wait...',
      passiveCount:'please wait...',
      dataTutor:'0',
      dataForum: '0',
      //zoom
      sdkKey: "",
      leaveUrl: "http://192.168.0.143:8081/home",
      meetingNumber: "98214604436",
      passWord: "103369",
      role: 1,
      signature: "",
      userEmail: "",
      userName: "Vue.js",
      apiKey:"Uc-CMfutTyK7ZJ47ZvtOiw"
    }
  },
  computed:{
    userDetail : function(){ return this.$store.getters.StateUser},
    client(){
      const client = ZoomMtgEmbedded.createClient();
      return client
    }
  },
  methods:{
    getData(){
      let loader = this.$loading.show({
        // Optional parameters
        container: null,
      });
      this.dataLoading=true
      window.axios.get('/student/count').then(
        res=>{
          this.studentCount = res.data.data
        }
      )
      window.axios.get('/student/passive/count').then(
        res=>{
          this.passiveCount = res.data.data
        }
      )
      window.axios.get('/user/tutor/get').then(
        res=>{
          this.dataTutor = res.data.data
        }
      )
      window.axios.get('/forum/topic/admin').then(
        res=>{
          this.dataForum = res.data.data
          this.dataLoading=false
          loader.hide()
        }
      )
    },
    getZoomSignature(){
      window.axios.post('/zoom/signature',{
        meeting_number:this.meetingNumber,
        role:this.role
      }).then(
        res=>{
          console.log(res.data.data)
          this.signature = res.data.data
        }
      )
    },
    startMeeting() {
      let meetingSDKElement = this.$refs.zoomComp;
      this.client.init({
        debug: true,
        zoomAppRoot: meetingSDKElement,
        language: 'en-US',
        customize: {
          meetingInfo: [
            'topic',
            'host',
            'mn',
            'pwd',
            'telPwd',
            'invite',
            'participant',
            'dc',
            'enctype',
          ],
          toolbar: {
            buttons: [
              {
                text: 'Custom Button',
                className: 'CustomButton',
                onClick: () => {
                  console.log('custom button');
                },
              },
            ],
          },
        },
      });
      this.client.join({
        apiKey: this.apiKey,
        signature: this.signature,
        meetingNumber: this.meetingNumber,
        password: this.passWord,
        userName: this.userName
      })
    }

  },
  created(){
    this.getZoomSignature()
  },
  mounted(){
    this.getData()
  }
}
</script>

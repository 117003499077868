import Vue from 'vue'
import VueRouter from 'vue-router'

import LoginView from '../views/LoginView.vue'
import HomeView from '../views/HomeView.vue'

import AccountManagement from '../views/AccountManagement.vue'
import Student from '../views/Student.vue'
import StudentDetail from '../views/StudentDetail.vue'
import Package from '../views/Package.vue'
import Batch from '../views/Batch.vue'
import Meet from '../views/Meet.vue'
import Lesson from '../views/Lesson.vue'
import Schedule from '../views/Schedule.vue'
import Payment from '../views/Payment.vue'
import Order from '../views/Order.vue'
import Chat from '../views/Chat.vue'
import Forum from '../views/Forum.vue'
import Zoom from '../views/Zoom.vue'


import About from '../views/About.vue'
import Privacy from '../views/Privacy.vue'
import Terms from '../views/Terms.vue'
import Faq from '../views/Faq.vue'
import Banner from '../views/Banner.vue'
import Vocab from '../views/Vocab.vue'
import Contact from '../views/Contact.vue'
import MainBanner from '../views/MainBanner.vue'


import NotFound from '../views/404.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: LoginView
  },
  {
    path: '/home',
    name: 'Dashboard',
    component: HomeView
  },
  {
    path: '/user',
    name: 'Account Management',
    component: AccountManagement
  },
  {
    path: '/package',
    name: 'Package',
    component: Package
  },
  {
    path: '/batch',
    name: 'Batch',
    component: Batch
  },
  {
    path: '/lesson',
    name: 'Lesson',
    component: Lesson
  },
  {
    path: '/student',
    name: 'Student',
    component: Student
  },
  {
    path: '/schedule',
    name: 'Schedule',
    component: Schedule
  },
  {
    path: '/student/:id',
    name: 'Detail Student',
    component: StudentDetail
  },
  {
    path: '/meet/:id',
    name: 'Meet',
    component: Meet
  },
  {
    path: '/forum',
    name: 'Forum',
    component: Forum
  },
  {
    path: '/banner',
    name: 'Banner',
    component: Banner
  },
  {
    path: '/zoom',
    name: 'Zoom',
    component: Zoom
  },
  {
    path: '/faq',
    name: 'Frequently Asked Question',
    component: Faq
  },
  {
    path: '/about',
    name: 'About Us',
    component: About
  },
  {
    path: '/main-banner',
    name: 'Main Banner',
    component: MainBanner
  },
  {
    path: '/privacy',
    name: 'Privacy Policies',
    component: Privacy
  },
  {
    path: '/terms',
    name: 'Terms and Condition',
    component: Terms
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/vocab',
    name: 'Vocabulary',
    component: Vocab
  },
  {
    path: '/payment',
    name: 'Payment',
    component: Payment
  },
  {
    path: '/order',
    name: 'Order',
    component: Order
  },
  {
    path: '/chat',
    name: 'Chat',
    component: Chat
  },
  {
    path: '*',
    name: '404',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

import Vue from 'vue'
// import Quill from 'quill';
import  ImageResize  from 'quill-image-resize-module';
import VueQuillEditor, {Quill} from 'vue-quill-editor'

import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.bubble.css' // for bubble theme
import 'quill/dist/quill.snow.css'

var BaseImageFormat = Quill.import('formats/image');
const ImageFormatAttributesList = [
    'alt',
    'height',
    'width',
    'style'
];
class ImageFormat extends BaseImageFormat {
    static formats(domNode) {
        return ImageFormatAttributesList.reduce(function(formats, attribute) {
            if (domNode.hasAttribute(attribute)) {
            formats[attribute] = domNode.getAttribute(attribute);
            }
            return formats;
        }, {});
    }
    format(name, value) {
        if (ImageFormatAttributesList.indexOf(name) > -1) {
            if (value) {
            this.domNode.setAttribute(name, value);
            } else {
            this.domNode.removeAttribute(name);
            }
        } else {
            super.format(name, value);
        }
    }
}

Quill.register(ImageFormat, true);
Quill.register('modules/imageResize', ImageResize);

var editorOption = {
    modules: {
        toolbar: {
            container: [
                
                ['bold', 'italic', 'underline', 'strike'],
                [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
                ['link']
            ]
        },
        imageResize: {
            modules: [ 'Resize', 'DisplaySize', 'Toolbar' ]
        }
    }
}
Vue.use(VueQuillEditor, editorOption)
<template>
  <basic-layout>
  <!--CREATE DIALOG -->
    <v-dialog
        v-model="lessonDialog"
        width="500"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Detail Lesson
          </v-card-title>

          <v-card-text class="pa-5">
            <v-data-table
              :headers="headersLesson"
              :items="dataLesson"
              item-key="name"
              class="elevation-1"
            >
              <template v-slot:top>
              </template>
              <template v-slot:item="{ item }">
                <tr>
                  <td>
                    {{item.is_pretest == 1 ? 'pretest' : (item.is_posttest == 1 ? 'posttest' : item.lesson)}}
                  </td>
                  <td>
                    {{item.score == null ? 'not graded' : item.score.score}}
                  </td>
                </tr>
              </template>

            </v-data-table>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red"
              text
              @click="closeLessonDialog"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <!-- ENDDIALOG -->
    <v-container
    >
      <v-row>
        <v-col
        cols="8"
        >
          <v-btn color="red" @click="$router.push({path:'/student'})">Back</v-btn>
          <v-card class="pa-2">
            <v-btn-toggle v-model="toggle_one" class="mb-2">
              <v-btn
              @click="page = 0;"
              >
                Profile
              </v-btn>
              <v-btn 
              @click="page = 1;"
              >
                Package
              </v-btn>
              <v-btn
              @click="page = 2;"
              >
                Batch
              </v-btn>
              <!-- <v-btn
              @click="page = 3;"
              >
                Score
              </v-btn> -->
              <v-btn
              @click="page = 4;"
              >
                Payment
              </v-btn>
            </v-btn-toggle>
            <hr>
            <v-container
            v-if="page == 0"
            >
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="data.profile.full_name"
                    counter
                    :rules="[rules.required]"
                    label="Full Name"
                    class="fill-width"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="data.username"
                    disabled
                    counter
                    :rules="[rules.required]"
                    label="Username"
                    class="fill-width"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="data.profile.occupancy"
                    counter
                    :rules="[rules.required]"
                    label="Occupancy"
                    class="fill-width"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="data.profile.email"
                    counter
                    :rules="[rules.required]"
                    label="E-mail"
                    class="fill-width"
                    required
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="data.profile.phone"
                    counter
                    :rules="[rules.required]"
                    label="Phone"
                    class="fill-width"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    :items="[{value:1,text:'Female'},
                    {value:0,text:'Male'},
                    ]"
                    item-text="text"
                    item-value="value"
                    label="Day"
                    v-model="data.profile.gender"
                  ></v-select>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="data.profile.birthday"
                    counter
                    :rules="[rules.required]"
                    label="Birthdate"
                    class="fill-width"
                    required
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="data.profile.age"
                    counter
                    :rules="[rules.required]"
                    label="Age"
                    class="fill-width"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="data.profile.gender"
                    counter
                    :rules="[rules.required]"
                    label="City"
                    class="fill-width"
                    required
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="data.profile.province"
                    counter
                    :rules="[rules.required]"
                    label="Province"
                    class="fill-width"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="data.profile.parent_name"
                    counter
                    :rules="[rules.required]"
                    label="Parent's Name"
                    class="fill-width"
                    required
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="data.profile.parent_number"
                    counter
                    :rules="[rules.required]"
                    label="Parent's Number"
                    class="fill-width"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <!-- PACKAGE/ -->
            <v-container v-if="page == 1">
              <v-row>
                <v-col>
                  <v-data-table
                    :headers="headersPackage"
                    :items="data.packet"
                    item-key="name"
                    class="elevation-1"
                    :search="search"
                  >
                    <template v-slot:top>
                    </template>
                    <template v-slot:item="{ item }">
                      <tr>
                        <td>
                          {{item.packet.name}}
                        </td>
                        <td>
                          {{item.packet.category}}
                        </td>
                        <td>
                          {{item.label}}
                        </td>
                        <td>
                          <v-btn icon color="black" outlined
                          v-if="item.packet.type == 'exam'"
                          class="mx-1"
                          @click="openLessonDialog(item.packet)"
                          >
                            <v-icon>mdi mdi-eye</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </template>

                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
              <!-- BATCH/ -->
              <v-container v-if="page == 2">
                <v-row>
                  <v-col>
                    <v-data-table
                      :headers="headersBatch"
                      :items="data.batch"
                      item-key="name"
                      class="elevation-1"
                      :search="search"
                    >
                      <template v-slot:top>
                      </template>
                      <template v-slot:item="{ item }">
                        <tr>
                          <td>
                            {{item.batch.name}}
                          </td>
                          <td>
                            {{item.batch.packet.name}}
                          </td>
                          <td>
                            <ul>
                              <li 
                                v-for="n in item.batch.schedule"
                                :key="n"
                              >
                              {{genDay(n.day) + ' | '+ n.start_time + ' - ' + n.end_time}}
                              </li>
                            </ul>
                          </td>
                          <td>
                            {{item.score == null ? 'Not Graded' : item.score}}
                          </td>
                        </tr>
                      </template>

                    </v-data-table>
                  </v-col>
                </v-row>
              </v-container>
              <!-- SCORE/ -->
              <v-container v-if="page == 3">
                <v-row>
                  <v-col>
                    <v-data-table
                      :headers="headersBatch"
                      :items="data.batch"
                      item-key="name"
                      class="elevation-1"
                      :search="search"
                    >
                      <template v-slot:top>
                      </template>
                      <template v-slot:item="{ item }">
                        <tr>
                          <td>
                            {{item.batch.name}}
                          </td>
                          <td>
                            {{item.batch.packet.name}}
                          </td>
                          <td>
                            <ul>
                              <li 
                                v-for="n in item.batch.schedule"
                                :key="n"
                              >
                              {{genDay(n.day) + ' | '+ n.start_time + ' - ' + n.end_time}}
                              </li>
                            </ul>
                          </td>
                          <td>
                            {{item.batch.packet.name}}
                          </td>
                        </tr>
                      </template>

                    </v-data-table>
                  </v-col>
                </v-row>
              </v-container>
              <!-- PAYMENT/ -->
              <v-container v-if="page == 4">
                <v-row>
                  <v-col>
                    <v-data-table
                      :headers="headersPayment"
                      :items="data.order"
                      item-key="name"
                      class="elevation-1"
                      :search="search"
                    >
                      <template v-slot:top>
                      </template>
                      <template v-slot:item="{ item }">
                        <tr>
                          <td>
                            {{item.invoice_code}}
                          </td>
                          <td>
                            {{item.packet.name}}
                          </td>
                          <td>
                            {{item.price}}
                          </td>
                          <td>
                            {{item.status}}
                          </td>
                        </tr>
                      </template>

                    </v-data-table>
                  </v-col>
                </v-row>
              </v-container>
          </v-card>
        </v-col>
        <v-col>
          <v-card class="d-flex justify-center pa-2">
            <v-avatar
              size="avatarSize"
              color="red"
            >
              <img :src="data.profile.photo" alt="alt">
            </v-avatar>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
        <!-- SCORE/ -->
    <v-container v-if="page == 3">
      
    </v-container>
        <!-- PAYMENT/ -->
    <v-container v-if="page == 4">
      
    </v-container>
  </basic-layout>
</template>

<script>
// @ is an alias to /src
import BasicLayout from '@/components/BasicLayout.vue'
export default {
  name: 'StudentDetail',
  components: {
    BasicLayout
  },
  data () {
    return {
      data: {},
      dataLesson: [],
      toggle_one:0,
      page:0,
      lessonDialog : false,
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters',
        emailMatch: () => (`The email and password you entered don't match`),
      },
    }
  },computed:{
    headersPackage () {
        return [
          {
            text: 'Name',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          {
            text: 'Category',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          {
            text: 'Label',
            align: 'start',
            sortable: true,
            value: 'name',
          }
        ]
    },
    headersBatch () {
        return [
          {
            text: 'Name',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          {
            text: 'Package',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          {
            text: 'Schedule',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          {
            text: 'Final Score',
            align: 'start',
            sortable: true,
            value: 'name',
          }
        ]
    },
    headersLesson () {
        return [
          {
            text: 'Lesson',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          {
            text: 'Score',
            align: 'start',
            sortable: true,
            value: 'name',
          },
        ]
    },
    headersScore () {
        return [
          {
            text: 'Name',
            align: 'start',
            sortable: true,
            value: 'name',
          }
        ]
    },
    headersPayment () {
        return [
          {
            text: 'Invoice',
            align: 'start',
            sortable: true,
            value: 'invoice_code',
          },
          {
            text: 'Package',
            align: 'start',
            sortable: true,
            value: 'package',
          },
          {
            text: 'Price',
            align: 'start',
            sortable: true,
            value: 'price',
          },
          {
            text: 'Status',
            align: 'start',
            sortable: true,
            value: 'status',
          }
        ]
    },
  },
  methods:{
    getData(){
      window.axios.get('/guest/user/detail/'+this.$route.params.id).then(
        res=>{
          this.data = res.data.data
          console.log(this.data)
        }
      )
    },
    openLessonDialog(item){
      this.lessonDialog = true
      window.axios.get('/lesson?packet_id='+item.id + '&user_id='+this.$route.params.id).then(
        res=>{
          this.dataLesson = res.data.data
          console.log(this.dataLesson)
        }
      )
    },
    closeLessonDialog(){
      this.dataLesson = []
      this.lessonDialog = false
    },
    genDay(day){
      if(day == 1){
        return 'Monday'
      } else if (day == 2){
        return 'Tuesday'
      } else if (day == 3){
        return 'Wednesday'
      } else if (day == 4){
        return 'Thursday'
      } else if (day == 5){
        return 'Friday'
      } else if (day == 6){
        return 'Saturday'
      } else if (day == 7){
        return 'Sunday'
      }
    }

  },
  mounted(){
    this.getData()
  }
}
</script>

<template>
  <basic-layout>
    <!--CREATE DIALOG -->
    <v-dialog
        v-model="detailDialog"
        width="800"
        persistent
      >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Detail Package
        </v-card-title>

        <v-card-text class="pa-5">
          <v-container grid-list-xs>
            <v-row>
              <v-col
              aria-colspan="3"
              >
              <img width="50" :src="apiUrl+'/api/guest/asset/icon/packet/'+selectedData.icon" alt="">
              </v-col>
            </v-row>
            <v-row>
              <v-col
              aria-colspan="3"
              >
                <v-file-input
                  label="Icon"
                  truncate-length="15"
                  id="icon"
                ></v-file-input>
              </v-col>
            </v-row>
            <v-row
            v-if="!isCreate && selectedData.pdf != null"
            >
              <v-col
              aria-colspan="3"
              >
               <span>This Package Have PDF</span>
              </v-col>
            </v-row>
            <v-row>
              <v-col
              aria-colspan="3"
              >
                <v-file-input
                  label="PDF"
                  truncate-length="15"
                  id="pdf"
                ></v-file-input>
              </v-col>
            </v-row>
            <v-row>
              <v-col
              aria-colspan="3"
              >
                <v-select
                  :items="['exam','batch','sub']"
                  label="type"
                  dense
                  v-model="selectedData.type"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="selectedData.name"
                  counter
                  :rules="[rules.required]"
                  label="Name"
                  class="fill-width"
                  required
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="selectedData.price"
                  counter
                  :rules="[rules.required]"
                  label="Price (Rp)"
                  class="fill-width"
                  required
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="selectedData.discount"
                  counter
                  label="Discount(Rp)"
                  class="fill-width"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
              aria-colspan="3"
              >
                <v-textarea
                  outlined
                  name="input-7-4"
                  label="Short Description"
                  v-model="selectedData.short_description"
                ></v-textarea>
              </v-col>
              <v-col
              aria-colspan="1"
              >
                <v-text-field
                  v-model="selectedData.period"
                  counter
                  label="Period (Days) (0 for unlimited)"
                  class="fill-width"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mb-5">
              <v-col
              aria-colspan="3"
              >
                <quill-editor
                    ref="editor"
                    v-model="selectedData.description"
                    @change="onEditorChange($event)"
                    style="height:100%;"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
              aria-colspan="3"
              >
                <v-select
                  :items="['en','ina']"
                  label="category"
                  dense
                  v-model="selectedData.category"
                ></v-select>
              </v-col>
              <v-col>
                  <v-checkbox
                    v-model="selectedData.repeatable"
                    label="Repeatable"
                  ></v-checkbox>
              </v-col>
              <v-col>
                  <v-checkbox
                    v-model="selectedData.is_sale"
                    label="is Sale"
                  ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            text
            v-if="isCreate"
            @click="createPackage()"
          >
            Save
          </v-btn>
          <v-btn
            color="red"
            text
            v-else
            @click="updatePackage(selectedData.category,selectedData.id,selectedData)"
          >
            Save
          </v-btn>
          <v-btn
            color="red"
            text
            v-if="isCreate"
            @click="closeCreateDialog()"
          >
            Close
          </v-btn>
          <v-btn
            color="red"
            text
            v-else
            @click="closeUpdateDialog()"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
<!-- ENDDIALOG -->

<!-- SUB DIALOG -->
    <v-dialog
        v-model="subDialog"
        width="800"
        persistent
      >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Sub Package
        </v-card-title>

        <v-card-text class="pa-5">
      <v-data-table
        :headers="headersSub"
        :items="dataSub"
        item-key="name"
        class="elevation-1"
        :search="search"
        :loading=dataLoading
      >
        <template v-slot:top>
          <v-container>
            <v-row>
              <v-col>
              <v-btn color="red" @click="openAddSubDialog">Add</v-btn>
              </v-col>
            </v-row>
          </v-container>

        </template>
        <template v-slot:item="{ item ,index}">
          <tr>
            <td>{{item.packet.name}}</td>
            <td style="width:200px">
              <v-btn icon color="black" outlined
              class="mx-1"
              @click="deleteSub(item)"
              >
                <v-icon>mdi mdi-delete</v-icon>
              </v-btn>
              <v-btn icon color="black" outlined
              class="mx-1"
              :disabled="index == 0"
              @click="reorderSub('up',item)"
              >
                <v-icon>mdi mdi-arrow-up</v-icon>
              </v-btn>
              <v-btn icon color="black" outlined
              class="mx-1"
              :disabled="index == (dataSub.length-1)"
              @click="reorderSub('down',item.id)"
              >
                <v-icon>mdi mdi-arrow-down</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>

      </v-data-table>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            text
            @click="subDialog=false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

<!-- END SUB DIALOG -->
<!-- ADD SUB DIALOG -->
    <v-dialog
        v-model="addSubDialog"
        width="600"
        persistent
      >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
        Add Sub Package
        </v-card-title>

        <v-card-text class="pa-5">
          <v-container >
            <v-row>
              <v-col>
                  <v-select
                    :items="data"
                    item-text="name"
                    item-value="id"
                    label="Select "
                    dense
                    v-model="subData.packet_id"
                  ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            text
            @click="createSub"
          >
            Add
          </v-btn>
          <v-btn
            color="red"
            text
            @click="addSubDialog=false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

<!-- ENDADD SUB DIALOG -->

    <v-container fluid class=""
    >
      <v-data-table
        :headers="headers"
        :items="data"
        item-key="name"
        class="elevation-1"
        :search="search"
        :loading=dataLoading
      >
        <template v-slot:top>
          <v-container>
            <v-row>
              <v-col>
              <v-btn color="red" @click="openCreateDialog">Create</v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                Select Category:
                <v-btn text color="red" @click="getData('en')">English</v-btn>
                <v-btn text color="red" @click="getData('ina')">Indonesia</v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="search"
                  label="Search"
                  class="mx-4"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>

        </template>
        <template v-slot:item="{ item ,index}">
          <tr>
            <td>{{item.name}}</td>
            <td>{{item.price}}</td>
            <td>{{item.is_sale ? 'On Sale' : 'Off Sale'}}</td>
            <td style="width:200px">
              <v-btn icon color="black" outlined
              class="mx-1"
              @click="detailDialog = true;selectedData = item"
              >
                <v-icon>mdi mdi-eye</v-icon>
              </v-btn>
              <v-btn icon color="black" outlined
              class="mx-1"
              :disabled="index == 0"
              @click="reorder(item.category,'up',item.id)"
              >
                <v-icon>mdi mdi-arrow-up</v-icon>
              </v-btn>
              <v-btn icon color="black" outlined
              class="mx-1"
              :disabled="index == (data.length-1)"
              @click="reorder(item.category,'down',item.id)"
              >
                <v-icon>mdi mdi-arrow-down</v-icon>
              </v-btn>
            </td>
            <td>
              <v-btn icon color="black" outlined
              class="mx-1"
              @click="openSubDialog(item.id)"
              >
                <v-icon>mdi mdi-eye</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>

      </v-data-table>
    </v-container>
  </basic-layout>
</template>

<script>
// @ is an alias to /src
import BasicLayout from '@/components/BasicLayout.vue'
export default {
  name: 'HomeView',
  components: {
    BasicLayout
  },
  data () {
    return {
      apiUrl:'',
      subData:{
        packet_id:0
      },
      dataLoading: true,
      category: 'en',
      search: '',
      detailDialog: false,
      subDialog: false,
      addSubDialog: false,
      isCreate: false,
      defaultData:{
      },
      selectedData:{},
      selectedSub:{},
      data: [
      ],
      dataSub:[],
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters',
        emailMatch: () => (`The email and password you entered don't match`),
      },
    }
  },computed:{
    headers () {
        return [
          {
            text: 'Title',
            align: 'start',
            sortable: true,
            value: 'title',
          },
          {
            text: 'Price',
            align: 'start',
            sortable: true,
            value: 'price',
          },
          {
            text: 'Status',
            align: 'start',
            sortable: true,
            value: 'status',
          },
          {
            text: 'Action',
            align: 'start',
            sortable: false,
          },
          {
            text: 'Sub Package',
            align: 'start',
            sortable: false,
          },
        ]
    },
    headersSub () {
        return [
          {
            text: 'Name',
            align: 'start',
            sortable: true,
            value: 'title',
          },
          {
            text: 'Action',
            align: 'start',
            sortable: false,
          },
        ]
    }
  },
  methods:{
    onEditorChange({ quill, html, text,source}) {
      console.log('editor change!', quill, html, text,source)
      this.selectedData.description = html
    },
    deleteSub(item){
      window.axios.post('/package/sub/delete/'+item.id,{
      }).then(
        res=>{
          if(res.data.error){
            this.$toasted.show(res.data.message, {type:'error'})
          }else{
            this.$toasted.show(res.data.message, {type:'success'})
          }
          this.openSubDialog(item.packet_id)
        }
      )
    },
    openAddSubDialog(){
      this.addSubDialog = true
    },
    openSubDialog(id){
      this.selectedSub = id
      this.dataLoading=true
      window.axios.get('/package/sub?packet_id='+id).then(
        res=>{
          console.log(res)
          this.dataSub = res.data.data
          this.dataLoading=false
          this.subDialog = true
        }
      )
    },
    openCreateDialog(){
      this.isCreate = true 
      this.detailDialog = true
    },
    createSub(){
      console.log(this.selectedSub,this.subData.packet_id)
      var formData = new FormData()
      formData.append('packet_id',this.selectedSub)
      formData.append('sub_packet_id',this.subData.packet_id)
      window.axios.post('/package/sub/create',
        formData
      ).then(
        res=>{
          console.log(res)
          if(res.data.error){
            this.$toasted.show('Form unfulfilled', {type:'error'})
          }else{
            this.$toasted.show(res.data.message, {type:'success'})
            this.openSubDialog(this.selectedSub)
            this.addSubDialog = false
          }
        }
      )
    },
    createPackage(){
      if(this.selectedData.repeatable == undefined){
        this.selectedData.repeatable = 0
      }
      if(this.selectedData.is_sale == undefined){
        this.selectedData.is_sale = 0
      }
      var formData = new FormData()
      formData.append('name',this.selectedData.name)
      formData.append('category',this.selectedData.category)
      formData.append('description',this.selectedData.description)
      formData.append('short_description',this.selectedData.short_description)
      formData.append('price',this.selectedData.price)
      formData.append('discount',this.selectedData.discount)
      formData.append('period',this.selectedData.period ? 1:0)
      formData.append('repeatable',this.selectedData.repeatable)
      formData.append('is_sale',this.selectedData.is_sale? 1:0)

      var pdfFiles = document.querySelector('#pdf');
      formData.append("pdf", pdfFiles.files[0]);

      var imagefile = document.querySelector('#icon');
      formData.append("icon", imagefile.files[0]);

      window.axios.post('/package/create',
        formData
      ,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      ).then(
        res=>{
          console.log(res)
          if(res.data.error){
            this.$toasted.show('Form unfulfilled', {type:'error'})
          }else{
            this.$toasted.show(res.data.message, {type:'success'})
            this.getData(this.selectedData.category)
            this.detailDialog = false
            this.isCreate =false
          }
        }
      )
    },
    closeCreateDialog(){
      this.detailDialog = false
      this.selectedData={}
      this.isCreate =false
    },
    closeUpdateDialog(){
      this.detailDialog = false ;
      this.selectedData={}
    },
    updatePackage(cat,id,selectedData){
      var formData = new FormData()
      formData.append('name',selectedData.name)
      formData.append('category',selectedData.category)
      formData.append('description',selectedData.description)
      formData.append('short_description',selectedData.short_description)
      formData.append('price',selectedData.price)
      formData.append('discount',selectedData.discount)
      formData.append('period',selectedData.period)
      formData.append('repeatable',selectedData.repeatable ? 1 : 0)
      formData.append('is_sale',selectedData.is_sale? 1 : 0)
      formData.append('type',selectedData.type)

      var imagefile = document.querySelector('#icon');
      formData.append("icon", imagefile.files[0]);
      
      var pdfFiles = document.querySelector('#pdf');
      formData.append("pdf", pdfFiles.files[0]);

      window.axios.post('/package/update/'+id,
        formData
      ,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      ).then(
        res=>{
          console.log(res)
          if(res.data.error){
            this.$toasted.show('Form unfulfilled', {type:'error'})
          }else{
            this.$toasted.show(res.data.message, {type:'success'})
            this.getData(cat)
            this.detailDialog = false
          }
        }
      )
    },
    getData(category){
      this.dataLoading=true
      window.axios.get('/package/admin?category='+category).then(
        res=>{
          this.data = res.data.data
          this.dataLoading=false
        }
      )
    },
    reorder(cat,type,id){
      window.axios.post('/package/reorder/'+id,{
        type: type,
      }).then(
        res=>{
          if(res.data.error){
            this.$toasted.show(res.data.message, {type:'error'})
          }else{
            this.$toasted.show(res.data.message, {type:'success'})
          }
          this.getData(cat)
        }
      )
    },
    reorderSub(type,item){
      window.axios.post('/package/sub/reorder/'+item.id,{
        type: type,
      }).then(
        res=>{
          if(res.data.error){
            this.$toasted.show(res.data.message, {type:'error'})
          }else{
            this.$toasted.show(res.data.message, {type:'success'})
          }
          this.openSubDialog(item.packet_id)
        }
      )
    }
  },
  mounted(){
    this.apiUrl = process.env.VUE_APP_API_URL
    this.getData('en')
  }
}
</script>

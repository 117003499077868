<template>
  <basic-layout>
    <!--CREATE DIALOG -->
    <v-dialog
        v-model="createDialog"
        width="500"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Detail Banner
          </v-card-title>

          <v-card-text class="pa-5">
            <v-container>
              <v-row>
                <v-col>
                  <img width="300" :src="apiUrl+'/api/guest/asset/image/banner/'+selectedData.img_url" alt="">
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-file-input
                    label="Banner"
                    truncate-length="15"
                    id="image"
                  ></v-file-input>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    :items="[{text:'package',value:'packet'},{text:'Nothing',value:null}]"
                    item-text="text"
                    item-value="value"
                    label="Link to"
                    dense
                    v-model="selectedData.content_type"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row
              v-if="selectedData.content_type == 'packet'"
              >
                <v-col>
                  <v-select
                    :items="['en','ina']"
                    label="Category "
                    dense
                    v-model="category"
                    @change="getDataPackage()"
                    :readonly="!isCreate"
                  ></v-select>
                </v-col>
                <v-col>
                  <v-select
                    :readonly="!isCreate"
                    :items="dataPackage"
                    item-text="name"
                    item-value="id"
                    label="Select "
                    dense
                    v-model="selectedData.content_id"
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red"
              text
              v-if="isCreate"
              @click="create()"
            >
              Save
            </v-btn>
            <v-btn
              color="red"
              text
              v-if="isCreate"
              @click="closeCreateDialog()"
            >
              Close
            </v-btn>
            <v-btn
              color="red"
              text
              v-else
              @click="closeUpdateDialog()"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
<!-- ENDDIALOG -->
    <v-container fluid class=""
    >
      <v-data-table
        :headers="headers"
        :items="data"
        item-key="name"
        class="elevation-1"
        :search="search"
        :loading=dataLoading
      >
        <template v-slot:top>
          <v-container>
            <v-row>
              <v-col>
              <v-btn color="red" @click="openCreateDialog">Create</v-btn>
              </v-col>
            </v-row>
          </v-container>

        </template>
        <template v-slot:item="{ item ,index}">
          <tr>
            <td>
              <img width="200" :src="apiUrl+'/api/guest/asset/image/banner/'+item.img_url" alt="">
            </td>
            <td>
              {{item.content_type == 'packet' ? 'Package' : 'Nothing'}}
            </td>
            <td v-if="item.packet != null">
              {{item.packet.name}}
            </td>
            <td v-else>
            </td>
            <td>
              <v-btn icon color="black" outlined
              class="mx-1"
              @click="deleteItem(item);"
              >
                <v-icon>mdi mdi-delete</v-icon>
              </v-btn>
              <v-btn icon color="black" outlined
              class="mx-1"
              :disabled="index == 0"
              @click="reorder('up',item.id)"
              >
                <v-icon>mdi mdi-arrow-up</v-icon>
              </v-btn>
              <v-btn icon color="black" outlined
              class="mx-1"
              :disabled="index == (data.length-1)"
              @click="reorder('down',item.id)"
              >
                <v-icon>mdi mdi-arrow-down</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>

      </v-data-table>
    </v-container>
  </basic-layout>
</template>

<script>
// @ is an alias to /src
import BasicLayout from '@/components/BasicLayout.vue'
export default {
  name: 'HomeView',
  components: {
    BasicLayout
  },
  data () {
    return {
      apiUrl:'',
      isCreate:false,
      dataLoading: true,
      search: '',
      selectedData: {},
      createDialog: false,
      data: [
      ],
      category: 'en',
      dataPackage: [
      ],
    }
  },computed:{
    headers () {
        return [
          {
            text: 'Image',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          {
            text: 'Link To',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          {
            text: 'Content',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          {
            text: 'Action',
            align: 'start',
            sortable: false,
          },
        ]
    }
  },
  methods:{
    reorder(type,id){
      window.axios.post('/banner/reorder/'+id,{
        type: type,
      }).then(
        res=>{
          if(res.data.error){
            this.$toasted.show(res.data.message, {type:'error'})
          }else{
            this.$toasted.show(res.data.message, {type:'success'})
          }
          this.getData()
        }
      )
    },
    deleteItem(item){
      window.axios.post('/banner/delete/'+item.id).then(
        res=>{
          if(res.data.error){
            this.$toasted.show(res.data.message, {type:'error'})
          }else{
            this.$toasted.show(res.data.message, {type:'success'})
          }
          this.getData()
        }
      )
    },
    create(){
      if(this.selectedData.content_id == undefined){
        this.selectedData.content_id =null
      }
      var formData = new FormData()
      var imagefile = document.querySelector('#image');
      formData.append("image", imagefile.files[0]);
      formData.append('content_type',this.selectedData.content_type)
      formData.append('content_id',this.selectedData.content_id)

      window.axios.post('/banner/create',formData,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(
        res=>{
          console.log(res)
          if(res.data.error){
            this.$toasted.show(res.data.message, {type:'error'})
          }else{
            this.$toasted.show(res.data.message, {type:'success'})
          }
          this.getData()
          this.createDialog = false
        }
      )
    },
    closeCreateDialog(){
      this.createDialog = false
      this.selectedData={}
      this.isCreate =false
    },
    closeUpdateDialog(){
      this.createDialog = false ;
      this.selectedData={}
    },
    getData(){
      window.axios.get('/guest/banner').then(
        res=>{
          this.data = res.data.data
          this.dataLoading=false
        }
      )
    },
    getDataPackage(){
      window.axios.get('/package/admin?category='+this.category).then(
        res=>{
          this.dataPackage = res.data.data
        }
      )
    },
    selectItem(item){
      this.selectedData = item
      console.log(this.selectedData)
    },
    openCreateDialog(){
      this.isCreate =true
      this.selectedData ={}
      this.createDialog = true ;
    },
  },
  mounted(){
    this.apiUrl = process.env.VUE_APP_API_URL
    this.getData()
    this.getDataPackage()
  }
}
</script>

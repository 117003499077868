<template>
  <basic-layout>
    <v-container fluid class=""
    >
    <img width="500" :src="apiUrl+'/api/guest/asset/image/banner/'+data" alt="">
    <v-file-input
      label="Image"
      truncate-length="15"
      id="image"
    ></v-file-input>
    <v-btn color="success" @click="save">Save</v-btn>
    </v-container>
  </basic-layout>
</template>

<script>
// @ is an alias to /src
import BasicLayout from '@/components/BasicLayout.vue'
export default {
  name: 'HomeView',
  components: {
    BasicLayout
  },
  data () {
    return {
      apiUrl:'',
      dataLoading: true,
      search: '',
      data: [
      ],
      createDialog : false,
      updateDialog : false,
      selectedData: {}
    }
  },computed:{
    headers () {
        return [
          {
            text: 'Question',
            align: 'start',
            sortable: true,
            value: 'question',
          },
          {
            text: 'Answer',
            align: 'start',
            sortable: true,
            value: 'answer',
          },
          {
            text: 'Action',
            align: 'start',
            sortable: false,
          },
        ]
    }
  },
  methods:{
    getData(){
      window.axios.get('/guest/home/content/get/banner').then(
        res=>{
          console.log(res)
          this.data = res.data.data
          this.dataLoading=false
        }
      )
    },
    save(){
      var formData = new FormData()
      var imagefile = document.querySelector('#image');
      formData.append("image", imagefile.files[0]);
      this.$toasted.show('Please Wait...', {type:'success'})
      window.axios.post('/home/content/update/banner',
        formData,{
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      ).then(
        res=>{
          console.log(res)
          if(res.data.error){
            this.$toasted.show(res.data.message, {type:'error'})
          }else{
            this.$toasted.show(res.data.message, {type:'success'})
          }
          this.createDialog =false
          this.getData()
        }
      )
    },
  },
  mounted(){
    this.apiUrl = process.env.VUE_APP_API_URL
    this.getData()
    this.dataLoading=false
  }
}
</script>

<template>
  <v-container fluid class=" fill-height pa-0 ma-0">
      <v-row>
        <v-flex justify-center class="d-flex justify-center fill-width">
          <v-col class=""
            cols="4"
          >
            <v-card elevation="5" height="400" class="rounded-lg">
              <div class="d-flex flex-column justify-center align-center fill-height">
                <div class="text-h4">404 NOTFOUND</div>

              </div>
            </v-card>
          </v-col>
        </v-flex>
      </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
export default {
  name:'LoginView',
  data () {
    return {
      loginInfo:{
        username: '',
        password: ''
      },
      show1: false,
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters',
        emailMatch: () => (`The email and password you entered don't match`),
      },
    }
  },
  methods:{
    loginAdmin(){
      axios.get('https://jsonplaceholder.typicode.com/todos/1').then((response) => {
        console.log(response.data)
      })
      this.$router.push({ path: `/home` })
    },
    loginTutor(){
      axios.get('https://jsonplaceholder.typicode.com/todos/1').then((response) => {
        console.log(response.data)
      })
    }
  }
}
</script>

<style>

</style>'
<template>
  <basic-layout>
    <v-container fluid class=""
    >
      <v-data-table
        :headers="headers"
        :items="data"
        item-key="name"
        class="elevation-1"
        :loading=dataLoading
      >
        <template v-slot:top>
          <v-text-field
            v-model="search"
            label="Search"
            class="mx-4"
          ></v-text-field>
        </template>
        <template v-slot:item="{ item }">
          <tr class="yellow"
          v-if="item.status == 'unread'">
            <td>{{item.user.realname == ''? item.user.username : item.user.realname}}</td>
            <td>{{item.message}}</td>
            <td>
              <v-btn icon color="black" outlined>
                <v-icon @click="openDetail(item)">mdi mdi-eye</v-icon>
              </v-btn>
            </td>
          </tr>
          <tr class=""
          v-else>
            <td>{{item.user.realname == ''? item.user.username : item.user.realname}}</td>
            <td>{{item.message}}</td>
            <td>
              <v-btn icon color="black" outlined>
                <v-icon @click="openDetail(item)">mdi mdi-eye</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>

      </v-data-table>

      <!-- DIALOG -->
      <v-dialog
        v-model="dialogDetail"
        width="60%"
        persistent
      >
        <v-card>
          <v-card-title class="text-h6 red">
            Detail Chat : {{selectedItem.user.realname}}
          </v-card-title>

          <v-card-text>
            <div class="d-flex flex-column" style="position:relative;">
              <div class="pa-2 overflow-auto" style="height:400px;margin-bottom:100px;">
                <div
                  v-for="(n,index) in conversation"
                  :key="index"
                >
                  <div class="my-2 d-flex justify-end"
                  v-if="n.is_me">
                    <v-card width="400">
                      {{n.content}}
                    </v-card>
                  </div>
                  <div class="my-2 d-flex justify-start"
                  v-else
                  >
                    <v-card width="400">
                      {{n.content}}
                    </v-card>
                  </div>
                </div>


              </div>
              <div style="position:absolute;bottom:0;width:100%;">
                <div class="d-flex align-content-center">
                <v-text-field
                  name="name"
                  label="message"
                  id="id"
                  outlined
                  v-model="message"
                ></v-text-field>
                <v-btn color="red"
                @click="sendMessage"
                >Send
                </v-btn>
                </div>

              </div>
            </div>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red"
              text
              @click="dialogDetail = false;closeDialog();"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </basic-layout>
</template>

<script>
// @ is an alias to /src
import BasicLayout from '@/components/BasicLayout.vue'
export default {
  name: 'HomeView',
  components: {
    BasicLayout
  },
  data () {
    return {
      dialogDetail:false,
      dataLoading: true,
      pollInterval: null,
      selectedItem: {
        user:{
          realname:''
        }
      },
      search: '',
      data: [
      ],
      conversation:[],
      message:''
    }
  },computed:{
    headers () {
        return [
          {
            text: 'Name',
            align: 'start',
            sortable: true,
            value: 'name',
          },
          {
            text: 'Last Chat',
            align: 'start',
            sortable: true,
            value: 'username',
          },
          {
            text: 'Action',
            align: 'start',
            sortable: false,
          },
        ]
    },
    userDetail : function(){ return this.$store.getters.StateUser},
  },
  methods:{
    openDetail (item) {
      this.selectedItem = item
      window.axios.get('/admin/chat/conversation/list?tc=private&type=first&data='+item.user.id).then(
        res=>{
          this.conversation = res.data.data
          console.log(this.conversation)
        }
      )
      this.$polling.addObserver('https://kwikku.in/eie-rebase/public/api/admin/chat/conversation/list?tc=private&type=first&data='+item.user.id, {
        interval: 1000,  // 2000ms by default
        maxFails: 10,    // break after 10 (5 by defualt) continuous failures
        headers: {
            'Content-Type': 'application/json',
            'Authorization' : `Bearer ${this.userDetail.access_token}` 
        }
      })
      this.$options.listeners['https://kwikku.in/eie-rebase/public/api/admin/chat/conversation/list?tc=private&type=first&data='+item.user.id] = (resp) => {
        this.conversation = resp.data.data
      }
      
      this.dialogDetail=true;
    },
    closeDialog(){
    this.$polling.removeObserver('https://kwikku.in/eie-rebase/public/api/admin/chat/conversation/list?tc=private&type=first&data='+this.selectedItem.user.id)
    delete this.$options.listeners['https://kwikku.in/eie-rebase/public/api/admin/chat/conversation/list?tc=private&type=first&data='+this.selectedItem.user.id]
    },
    getData (){
      window.axios.get('/admin/chat/list').then(
        res=>{
          this.data = res.data.data
          console.log(res)
        }
      )
    },
    sendMessage (){
      window.axios.post('/admin/chat/send',{
        tc: 'private',
        uid: this.selectedItem.user.id,
        content: this.message,
      }).then(
        res=>{
          this.data = res.data.data
          this.message = ''
          console.log(res)
        }
      )
    }
  },
  mounted(){
    this.dataLoading=false
    this.getData()
    // this.pollInterval = setInterval(this.getData(), 1000)

    this.$polling.addObserver('https://kwikku.in/eie-rebase/public/api/admin/chat/list', {
      interval: 2000,  // 2000ms by default
      maxFails: 10,    // break after 10 (5 by defualt) continuous failures
      headers: {
          'Content-Type': 'application/json',
          'Authorization' : `Bearer ${this.userDetail.access_token}` 
      }
    })
    this.$options.listeners['https://kwikku.in/eie-rebase/public/api/admin/chat/list'] = (resp) => {
    // do with HTTP response object
    this.data = resp.data.data
    }
  },
  destroyed(){
    this.$polling.removeObserver('https://kwikku.in/eie-rebase/public/api/admin/chat/list')
    delete this.$options.listeners['https://kwikku.in/eie-rebase/public/api/admin/chat/list']
  }
}
</script>

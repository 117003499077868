<template>
  <basic-layout>
    <v-container fluid class=""
      v-if="ZoomToken != null || ZoomToken != undefined"
    >
    <v-row>
      <v-col
        class="fill-height ma-0"
        z-index="1"
        style=""
        cols="4"
      >
        <v-card class="elevation-10">
          <v-card-title primary-title>
            <v-icon>mdi mdi-comment-quote</v-icon>
            Zoom
          </v-card-title>
          <v-card-text>
            <v-btn
              color="red"
              text
              @click="startMeeting()"
            >
              Start Meeting
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>


    <v-row >
      <v-col cols="12"> 
      <div ref="zoomComp" id="”meetingSDKElement”" style="position:absolute;top:0:left:0;width:100vw;height:100vh;"> 
        <!-- Zoom Meeting SDK Rendered Here -->
      </div>
      </v-col>
    </v-row>
    </v-container>
    <v-container v-else>
    <a :href="'https://zoom.us/oauth/authorize?response_type=code&client_id=THiRaFBJRhu76s93_BGcXg&redirect_uri=https%3A%2F%2Fkwikku.in%2Feie-rebase%2Fpublic%2Fapi%2Fzoom%2Foauth%2Fredirect%3Fuser_id%3D'+userDetail.user.id">
    <v-btn
    >
      Connect Zoom
    </v-btn>
    </a>
    </v-container>
  </basic-layout>
</template>

<script>
// @ is an alias to /src
import BasicLayout from '@/components/BasicLayout.vue'
import ZoomMtgEmbedded from '@zoomus/websdk/embedded';

import { axios } from 'axios';
import { mapActions } from "vuex";

export default {
  name: 'HomeView',
  components: {
    BasicLayout
  },
  data (){
    return{
      studentCount:'please wait...',
      passiveCount:'please wait...',
      dataTutor:'0',
      dataForum: '0',
      //zoom
      sdkKey: "",
      leaveUrl: "http://192.168.0.143:8081/home",
      meetingNumber: "98214604436",
      passWord: "103369",
      role: 1,
      signature: "",
      userEmail: "",
      userName: "Vue.js",
      apiKey:"Uc-CMfutTyK7ZJ47ZvtOiw"
    }
  },
  computed:{
    ZoomToken : function(){ return this.$store.getters.StateZoomToken},
    client(){
      const client = ZoomMtgEmbedded.createClient();
      return client
    }
  },
  methods:{
    ...mapActions(["setZoomToken"]),
    getZoomSignature(){
      window.axios.post('/zoom/signature',{
        meeting_number:this.meetingNumber,
        role:this.role
      }).then(
        res=>{
          console.log(res.data.data)
          this.signature = res.data.data
        }
      )
    },
    startMeeting() {
      let meetingSDKElement = this.$refs.zoomComp;
      this.client.init({
        debug: true,
        zoomAppRoot: meetingSDKElement,
        language: 'en-US',
        customize: {
          meetingInfo: [
            'topic',
            'host',
            'mn',
            'pwd',
            'telPwd',
            'invite',
            'participant',
            'dc',
            'enctype',
          ],
          toolbar: {
            buttons: [
              {
                text: 'Custom Button',
                className: 'CustomButton',
                onClick: () => {
                  console.log('custom button');
                },
              },
            ],
          },
        },
      });
      this.client.join({
        apiKey: this.apiKey,
        signature: this.signature,
        meetingNumber: this.meetingNumber,
        password: this.passWord,
        userName: this.userName
      })
    }

  },
  created(){
    this.getZoomSignature()
  },
  mounted(){
    console.log(this.$route)
    if(this.$route.query.access_token != null || this.$route.params.access_token != undefined ){
      
      this.setZoomToken(this.$route.query.access_token)
      this.$router.replace({'query': null})
    }
  }
}
</script>
